/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-lonely-if */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Close } from '@material-ui/icons';
import { ContainerJob, MarginContainer, OaPopin } from '@orientaction/commons';
import { ExpandableItemJob, JobLayout } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { validate } from 'email-validator';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Search } from '../../../Asset/svg/search.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import { useStyles } from './style';

const JobCandidates = (props: any) => {
  const { push } = useHistory();
  const classes = useStyles();
  const [dataCanditats, setDataCandidats] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [seekValue, setSeekValue] = useState<string>('');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [userDataToInvite, setUserDataToInvite] = useState<UserToInviteType>({
    firstname: '',
    lastname: '',
    email: '',
    idJob: '',
  });
  const [jobs, setJobs] = useState<Array<JobType>>([]);
  const [jobToSend, setJobToSend] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const userConsultant = JSON.parse(getFromLS('userInfos') || '{}');
  const [openPopin, setOpenPopin] = useState<boolean>(false);
  const [openPopinR, setOpenPopinR] = useState<boolean>(false);
  const [dataAfterChecking, setDataAfterChecking] = useState<any>();

  // const dataCanditats = [
  //   {
  //     title: 'Chef de Projet Marketing Opérationnel Marché Restauration Collective H/F',
  //     candidats: [
  //       {
  //         firstname: 'Toky',
  //         lastname: 'Ramarozavo',
  //         age: 27,
  //         genre: 'M',
  //         ville: 'Ambod',
  //         diplome: 'Bacc +4',
  //         phonenumber: '03487',
  //         email: 'tnrama',
  //         avancement: 'terminé',
  //       },
  //       {
  //         firstname: 'Toky nirina',
  //         lastname: 'Zamba',
  //         age: 20,
  //         genre: 'F',
  //         ville: 'Andran',
  //         diplome: 'Bacc +4',
  //         phonenumber: '0348',
  //         email: 'tnram',
  //         avancement: 'en cours',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Digital Strategy Associate / Senior Associate (DigitalBCG)',
  //     candidats: [
  //       {
  //         firstname: 'Zamba',
  //         lastname: 'Ramarozatovo',
  //         age: 27,
  //         genre: 'F',
  //         ville: 'Ambodivona ankadifosty',
  //         diplome: 'Bacc +4',
  //         phonenumber: '0348770557',
  //         email: 'tnramarozatovo@gmail.com',
  //         avancement: 'en cours',
  //       },
  //       {
  //         firstname: 'Toky nirina',
  //         lastname: 'Zamba',
  //         age: 27,
  //         genre: 'M',
  //         ville: 'Andranomena Ivato',
  //         diplome: 'Bacc +4',
  //         phonenumber: '0348770557',
  //         email: 'tnramaro@gmail.com',
  //         avancement: 'terminé',
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    fetchJobSeek(seekValue);
    fetchJobOnly();
  }, []);

  const updateValue = debounce(async event => {
    setLoading(true);
    const { name, value } = event.target;
    await fetchJobSeek(value);
    setLoading(false);
  }, 1000);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSeekValue(value);
    updateValue(e);
  };

  const onKeyDown = (event: any) => {
    updateValue(event);
  };

  const fetchJobSeek = async (keyword: string) => {
    setLoading(true);
    try {
      const seekResp = await apiServiceNative.get(`/api/jobs/search?query=${keyword}`);
      if (seekResp) {
        setDataCandidats(seekResp);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const fetchJobOnly = async () => {
    setLoading(true);
    try {
      const jobData = await apiServiceNative.get(
        `/api/jobs/consultant/${userConsultant.id}?pageSize=100`
      );
      if (jobData && jobData.data) {
        const jobsMapped = jobData.data.map((job: any) => {
          return {
            title: job.title,
            id: job.id,
          };
        });
        setJobs(jobsMapped);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    setUserDataToInvite({ ...userDataToInvite, [field]: value });
  };
  const sendData = async () => {
    if (!validate(userDataToInvite.email)) {
      setErrorEmail("L'adresse email n'a pas le bon format");
      return;
    }
    setErrorEmail('');
    const checking = await apiServiceNative.post(`/api/job/check-invitation-user`, {
      emailBenef: userDataToInvite.email,
    });
    if (checking.status === 404) {
      const dataNewUser = await apiServiceNative.post(`/api/job/send-invitation/lambda`, {
        firstname: userDataToInvite.firstname,
        lastname: userDataToInvite.lastname,
        email: userDataToInvite.email,
        idJob: userDataToInvite.idJob,
        idUserConsultant: userConsultant.id,
      });
      setOpenDrawer(false);
    } else {
      if (checking.data.rattachement) {
        setDataAfterChecking(checking.data);
        setOpenPopinR(true);
      } else {
        setOpenPopin(true);
        console.log('not rattached');
      }
    }
    console.log({ userDataToInvite });
  };

  const handleConfirmRattachement = async () => {
    try {
      const dataNewUser = await apiServiceNative.post(`/api/job/send-invitation/notrattached`, {
        idUserConsultant: userConsultant.id,
        idJob: userDataToInvite.idJob,
        emailBenef: userDataToInvite.email,
      });
      setOpenDrawer(false);
      setOpenPopin(false);
    } catch (e) {
      console.error(e);
      setOpenPopin(false);
    }
  };

  const handleConfirmCandidat = async () => {
    try {
      const dataNewUser = await apiServiceNative.post(`/api/job/send-invitation/candidat`, {
        idJob: userDataToInvite.idJob,
        idUser: dataAfterChecking.id,
        idUserConsultant: userConsultant.id,
      });
      setOpenDrawer(false);
      setOpenPopinR(false);
    } catch (e) {
      console.error(e);
      setOpenPopinR(false);
    }
  };

  return (
    <JobLayout isaddBackground={true}>
      <ContainerJob>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}>
            <Grid item={true}>
              <Typography variant="h2" style={{ textTransform: 'none' }}>
                Candidat(e)s
              </Typography>
            </Grid>
            <Grid item={true} style={{ display: 'flex' }} alignItems="center">
              <OutlinedInput
                placeholder="Rechercher"
                className={classes.inputSearch}
                type="search"
                value={seekValue}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                id="outlined-adornment-amount"
                startAdornment={<Search style={{ marginRight: 10 }} />}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.btnPrimarySeek}
                onClick={() => setOpenDrawer(true)}>
                Inviter un(e) candidat(e)
              </Button>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          <div>
            {loading && <CircularProgress className={classes.loader} />}
            {!loading &&
              dataCanditats &&
              dataCanditats?.length > 0 &&
              dataCanditats?.map((elem: any) => (
                <ExpandableItemJob data={elem} query={seekValue} />
              ))}
            {!loading && dataCanditats?.length === 0 && (
              <Typography className={classes.textNoResult}>Aucun resultat</Typography>
            )}
          </div>
        </MarginContainer>
        <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
          <IconButton
            aria-label="delete"
            className={classes.iconBtnImage}
            style={{ background: 'transparent' }}
            onClick={() => setOpenDrawer(false)}>
            <Close style={{ fontSize: 30, color: 'rgb(230, 60, 60)' }} />
          </IconButton>
          <div className={classes.contentDrawer}>
            <Typography variant="h2" color="primary" className={classes.titleTests}>
              Inviter un(e) candidat(e)
            </Typography>
            <div className={classes.separator2} />
            <div style={{ marginTop: 40 }}>
              <div className={classes.containerField}>
                <h3 className={classes.label}>Nom</h3>
                <TextField
                  id="create-test-title"
                  variant="outlined"
                  className={classes.formInput}
                  name="test-name"
                  value={userDataToInvite.firstname}
                  onChange={e => {
                    handleFieldChange('firstname', e.target.value);
                  }}
                  placeholder="Indiquer le nom"
                />
              </div>
              <div className={classes.containerField}>
                <h3 className={classes.label}>Prénom</h3>
                <TextField
                  id="create-test-title"
                  variant="outlined"
                  className={classes.formInput}
                  name="test-name"
                  value={userDataToInvite.lastname}
                  onChange={e => {
                    handleFieldChange('lastname', e.target.value);
                  }}
                  placeholder="Indiquer le prénom"
                />
              </div>

              <div className={classes.containerField}>
                <h3 className={classes.label}>Email</h3>
                <TextField
                  id="create-test-title"
                  variant="outlined"
                  className={classes.formInput}
                  name="test-name"
                  value={userDataToInvite.email}
                  onChange={e => {
                    handleFieldChange('email', e.target.value);
                  }}
                  placeholder="Indiquer l'email"
                  type="email"
                  helperText={!errorEmail ? '' : 'Entrer un format email correct'}
                />
              </div>
              <div className={classes.containerField}>
                <h3 className={classes.label}>Annonce liée</h3>
                <Select
                  variant="outlined"
                  value={userDataToInvite.idJob}
                  onChange={e => handleFieldChange('idJob', e.target.value)}
                  className={classes.select}
                  displayEmpty={true}>
                  <MenuItem value="" disabled={true}>
                    Sélectionner une annonce
                  </MenuItem>
                  {jobs &&
                    jobs?.map((job: any, idx: number) => (
                      <MenuItem
                        key={job.id}
                        value={job.id}
                        style={{
                          color: userDataToInvite.idJob === job.id ? '#000A8C' : 'inherit',
                        }}>
                        {job.title}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <Grid container={true} spacing={2}>
                <Grid item={true} sm={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.btnCancel}
                    style={{ border: '1px solid #E63C3C' }}
                    onClick={() => setOpenDrawer(true)}>
                    Annuler
                  </Button>
                </Grid>
                <Grid item={true} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnConfirm}
                    disabled={
                      !userDataToInvite.email ||
                      !userDataToInvite.firstname ||
                      !userDataToInvite.lastname ||
                      !userDataToInvite.idJob
                    }
                    onClick={sendData}>
                    Confirmer
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Drawer>
        <OaPopin
          open={openPopin}
          title="Demande de rattachement d'un bénéficiaire."
          handleConfirm={handleConfirmRattachement}
          handleClose={() => setOpenPopin(false)}>
          <div>
            <Typography variant="h6" className={classes.modalText}>
              L'adresse e-mail que vous avez renseignée appartient à un utilisateur bénéficiaire
              non-rattaché. Souhaitez-vous le rattacher?
            </Typography>
          </div>
        </OaPopin>
        <OaPopin
          open={openPopinR}
          title="Demande de rattachement d'un bénéficiaire."
          handleConfirm={handleConfirmCandidat}
          handleClose={() => setOpenPopinR(false)}>
          <div>
            <Typography variant="h6" className={classes.modalText}>
              Ce candidat est déjà en prestation {dataAfterChecking?.service?.name} avec{' '}
              {`${dataAfterChecking?.upper_hierarchy?.lastname} ${dataAfterChecking?.upper_hierarchy?.firstname}`}{' '}
              souhaitez-vous l'ajouter à votre annonce ? Il recevra un email avec les informations
              de celle-ci.
            </Typography>
          </div>
        </OaPopin>
      </ContainerJob>
    </JobLayout>
  );
};

export default JobCandidates;
