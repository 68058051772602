import { Chip, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import GooglePlacesAutocomplete from '@orientaction/components/PlacesAutocomplete';
import { IAddress } from '@orientaction/components/PlacesAutocomplete/types';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './style';
import { IJobMatching } from './type';

const diplomas = [
  'CAP/BEP',
  'BAC',
  'BAC+1',
  'BAC+2',
  'BAC+3',
  'BAC+4',
  'BAC+5',
  'BAC+6',
  'BAC+7',
  'BAC+8',
];
const years = ['débutant accepté', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10 ans et plus'];
const disponibilities = [
  'dès que possible',
  '1 mois',
  '2 mois',
  '3 mois',
  '4 mois',
  '5 mois',
  '6 mois',
  '7 mois',
  '8 mois',
  '9 mois',
  '10 mois',
  '11 mois',
  '12 mois',
];
type JobFormProps = {
  job?: IJobMatching;
  handleChange: (e: any) => void;
  setJob: any;
  errors?: any;
};
const JobForm: FC<JobFormProps> = ({ job, handleChange, setJob, errors = {} }) => {
  const classes = useStyles();
  const [placeValue, setPlaceValue] = useState('');
  const onPlaceChange = (address?: IAddress) => {
    if (address) {
      const { addressCity, addressLat, addressLng, addressZipcode } = address;
      setJob((prev: any) => ({
        ...prev,
        addressCity,
        addressLat,
        addressLng,
        addressZipcode,
      }));
    } else {
      setJob(null);
    }
  };
  useEffect(() => {
    if (job) {
      if (job.addressCity && job.addressZipcode) {
        setPlaceValue(`${job.addressCity}(${job.addressZipcode.substring(0, 2)})`);
      }
    }
  }, [job]);

  return (
    <>
      <Typography variant="h3" className={classes.subtitle}>
        Introduction
      </Typography>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Titre de l’annonce <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <TextField
              required={true}
              multiline={true}
              variant="outlined"
              className={classes.formInput}
              error={errors.title && !job?.title}
              name="title"
              value={job?.title ?? ''}
              onChange={handleChange}
              placeholder="Responsable des relations humaines"
            />
            {errors.title && !job?.title && (
              <Typography className={classes.errorText}>Champ obligatoire</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Présentation <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <TextField
              error={errors.intro && !job?.intro}
              value={job?.intro ?? ''}
              required={true}
              onChange={handleChange}
              variant="outlined"
              className={classes.formInput}
              name="intro"
              multiline={true}
              minRows={6}
              placeholder="Indiquez la présentation de votre annonce"
            />
            {errors.intro && !job?.intro && (
              <Typography className={classes.errorText}>Champ obligatoire</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Ville associée à l’emploi <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <GooglePlacesAutocomplete
              inputProps={{
                placeholder: 'Paris (75)',
                onKeyDown: e => {
                  if (e.key === 'Backspace') {
                    setPlaceValue('');
                    onPlaceChange();
                  }
                },
                onClick: e => setPlaceValue(''),
                error: errors.addressCity && !job?.addressCity,
              }}
              onDone={onPlaceChange}
              inputValue={placeValue}
              setInputValue={newValue => setPlaceValue(newValue)}
              label="Enter your location"
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            />

            {errors.addressCity && !job?.addressCity && (
              <Typography className={classes.errorText}>Champ obligatoire</Typography>
            )}
          </div>
        </Grid>
        <Grid item={true} xs={6}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Niveau de diplôme <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <Autocomplete
              className={classes.chips}
              placeholder="Sélectionner un ou plusieurs niveau de diplôme"
              multiple={true}
              options={diplomas}
              value={job?.degree_levels || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="default"
                    label={option}
                    color="primary"
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(_, degree_levels) => {
                setJob((prev: any) => ({ ...prev, degree_levels }));
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Sélectionner un ou plusieurs niveau de diplôme"
                  className={classes.formInput}
                  error={
                    errors.degree_levels && (!job?.degree_levels || !job?.degree_levels?.length)
                  }
                  name="degree_levels"
                />
              )}
            />
            {errors.degree_levels && (!job?.degree_levels || !job?.degree_levels?.length) && (
              <Typography className={classes.errorText}>Champ obligatoire</Typography>
            )}
          </div>
        </Grid>
      </Grid>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Années d’expériences <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <Autocomplete
              placeholder="Sélectionner une ou plusieurs années d'expériences"
              multiple={true}
              options={years}
              onChange={(_, year_experiences) => {
                setJob((prev: any) => ({ ...prev, year_experiences }));
              }}
              value={job?.year_experiences || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="default"
                    label={option}
                    color="primary"
                    className={classes.chips}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  placeholder="Sélectionner une ou plusieurs années d’expériences"
                  {...params}
                  variant="outlined"
                  error={
                    errors.year_experiences &&
                    (!job?.year_experiences || !job?.year_experiences?.length)
                  }
                  className={classes.formInput}
                  name="year_experiences"
                />
              )}
            />
          </div>
          {errors.year_experiences &&
            (!job?.year_experiences || !job?.year_experiences?.length) && (
              <Typography className={classes.errorText}>Champ obligatoire</Typography>
            )}
        </Grid>
        <Grid item={true} xs={6}>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Disponibilités <span className={classes.errorColor}>*</span>{' '}
            </Typography>
            <Autocomplete
              placeholder="Sélectionner un ou plusieurs disponibilité"
              multiple={true}
              value={job?.disponibilities || []}
              options={disponibilities}
              onChange={(_, d) => {
                setJob((prev: any) => ({ ...prev, disponibilities: d }));
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="default"
                    label={option}
                    color="primary"
                    className={classes.chips}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  error={
                    errors.disponibilities &&
                    (!job?.disponibilities || !job?.disponibilities?.length)
                  }
                  {...params}
                  variant="outlined"
                  className={classes.formInput}
                  name="diploma"
                  placeholder="Sélectionner un ou plusieurs disponibilité"
                />
              )}
            />
          </div>
          {errors.disponibilities && (!job?.disponibilities || !job?.disponibilities?.length) && (
            <Typography className={classes.errorText}>Champ obligatoire</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default JobForm;
