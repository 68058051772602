import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      textTransform: 'none',
      fontSize: 16,
      fontFamily: 'Open Sans',
      fontWeight: 700,
    },
    subtitle: {
      color: '#151528',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      lineHeight: 1.5,
      wordWrap: 'break-word',
      marginTop: 10,
      marginRight: 10,
    },
    description: {
      color: '#151528',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      lineHeight: 1.5,
      wordWrap: 'break-word',
      marginTop: 10,
      marginRight: 10,
    },
    descriptionOptionnel: {
      color: '#B0B5FF',
      fontWeight: 600,
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
    },
    label: {
      color: '#151528',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 500,
      lineHeight: 1.5,
      wordWrap: 'break-word',
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247',
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      }
    },
    errorColor: {
      color: 'red',
    },
    errorText: {
      color: 'red',
      fontSize: 11,
    },
    matchingSubCard: {
      borderRadius: 5,
      backgroundColor: '#ffffff',
      padding: 25,
      borderWidth: 1,
      borderStyle: 'solid',
      marginTop: 10,
    },
    fieldContainer: {
      paddingTop: 10,
      paddingRight: 10,
    },
    fieldset: {
      border: '1px dashed #A9AAB8',
      width: '100%',
      height: '100%',
    },
    fieldsetRating: {
      width: '100%',
      height: '100%',
      marginBottom: 70,
    },
    feldsetPadding: {
      paddingTop: '10px',
      paddingLeft: '10px',
    },
    paddingTop: {
      paddingTop: '10px',
    },
    marginVertical: {
      marginTop: 5,
      marginBottom: 5,
    },
    formControlLabel: {
      fontSize: '5px',
      '& .MuiFormControlLabel-label': {
        color: '#151528',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        width: '100%',
      },
    },
    radio: {
      paddingTop: '2px',
      paddingBottom: '2px',
      '& .Mui-checked': {
        color: '#000A8C',
      },
    },
    radioLabel: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
    radioLabelFirst: {
      width: 100,
    },
    langBtn: {
      color: 'white',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      lineHeight: 14,
      wordWrap: 'break-word',
      marginTop: 25,
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 15,
      paddingBottom: 15,
      background: '#000A8C',
      borderRadius: 5,
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
      display: 'inline-flex',
    },
    selectPlaceholder: {
      color: '#A9AAB8',
    },
    ratingRow: {
      border: '1px dashed #A9AAB8',
      padding: 5,
    },
    radioSelected: {
      backgroundColor: '#5B6FB64D',
    }
  })
);
