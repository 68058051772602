import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: '#0E1444',
      display: 'block',
    },
    root: {
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      background: '#F7F7FB',
      width: '100%',
      borderRadius: '50px 50px 0 0',
    },
    breadcrumbs: {
      paddingLeft: '50px',
      color: 'white',
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: 500,
      wordWrap: 'break-word',
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      color: 'white',
      fontSize: 30,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      fontWeight: 700,
      wordWrap: 'break-word',
      padding: '25px 0',
      textTransform: 'none',
    },
    marginTop: {
      marginTop: 40,
    },
    card: {
      backgroundColor: '#FFFFFF',
      padding: 40,
      borderRadius: 5,
    },
    footer: {
      position: 'fixed',
      backgroundColor: '#0E1444',
      bottom: 0,
      borderRadius: '40px 40px 0px 0px',
      textAlign: 'center',
    },
    draftButton: {
      margin: 5,
      color: 'white',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      lineHeight: 14,
      wordWrap: 'break-word',
      border: '1px solid #FFF',
      textTransform: 'none',
    },
    publishButton: {
      margin: 5,
      color: '#000A8C',
      fontSize: 14,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      lineHeight: 14,
      wordWrap: 'break-word',
      borderRadius: 5,
      background: '#FFF',
      textTransform: 'none',
      '&:hover': {
        border: '1px solid white',
        color: 'white',
      },
    },
    modalContent: {},
    modalText: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
  })
);
