/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import { JobLayout } from '@orientaction/components';
import JobForm from '@orientaction/components/Form/CreateEditAdmin/Job';
import MatchingForm from '@orientaction/components/Form/CreateEditAdmin/Job/matchingForm';
import { IJobMatching } from '@orientaction/components/Form/CreateEditAdmin/Job/type';
import useJobForm from '@orientaction/components/Form/CreateEditAdmin/Job/useJobForm';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStyles } from './style';

const PreviewJob = () => {
  const { id } = useParams<{ id: string }>();
  const {goBack} = useHistory();
  const [job, setJob] = useState<IJobMatching>();
  const { state } = useLocation<{state: { job: IJobMatching }}>();
  useEffect(() => {
    if (state && state.state.job) {
      setJob(state.state.job);
    }
  }, [state]);
  const [loading, setLoading] = useState(false);
  const { publishAfterPreview } = useJobForm();

  const classes = useStyles();

  const publishNow = async () => {
    console.log('Publish now');
    if (job) {
      await publishAfterPreview(id, job);
    }
  };
  return (
    <JobLayout>
      <Grid container={true} className={classes.container}>
        <Grid item={true}>
          <div>
            <div>
              <Grid container={true} spacing={2} direction="row" alignItems="center">
                <Grid item={true} xs={1} />
                <Grid item={true} xs={10}>
                  <Typography variant="h2" className={classes.title}>
                    {job?.title}
                  </Typography>{' '}
                  <ArrowBack fontSize="small" /> Revenir aux annonces
                </Grid>
              </Grid>
            </div>

            <div className={classes.root}>
              <form noValidate={true}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={1} />
                  <Grid item={true} xs={10}>
                    <Grid
                      container={true}
                      spacing={1}
                      className={clsx(classes.card, classes.marginTop)}>
                      <Grid item={true} xs={12}>
                        <JobForm job={job} handleChange={() => {}} setJob={null} />
                      </Grid>
                    </Grid>
                    <Grid
                      container={true}
                      spacing={1}
                      className={clsx(classes.card, classes.marginTop)}>
                      <Grid item={true} xs={12}>
                        <MatchingForm job={job} handleChange={() => {}} errors={{}} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.footer} container={true} spacing={2}>
                  <Grid item={true} xs={1} />
                  <Grid item={true} xs={5}>
                    <Button
                      type="button"
                      onClick={() => goBack()}
                      className={classes.draftButton}
                      variant="outlined"
                      disabled={loading}
                      color="primary">
                      Retourner à l&apos;éditeur
                    </Button>
                  </Grid>
                  <Grid item={true} xs={5}>
                    <Button
                      className={classes.publishButton}
                      type="button"
                      onClick={publishNow}
                      variant="contained"
                      color="primary"
                      disabled={loading}>
                      Confirmer et publier cette annonce
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </JobLayout>
  );
};

export default PreviewJob;
