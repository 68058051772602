import Board from './Board';
import Candidates from './Candidates';
import DetailCandidat from './DetailCandidat';
import Job from './Job';
import JobCandidates from './JobCandidates';
import NewJob from './NewJob';
import NewJobPreview from './NewJob/Preview';

export default {
  Board,
  Candidates,
  Job,
  NewJob,
  JobCandidates,
  NewJobPreview,
  DetailCandidat,
};
