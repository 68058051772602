/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContainerJob, InputSearch, MarginContainer } from '@orientaction/commons';
import { JobLayout, TableAnnonce, TableCandidat } from '@orientaction/components';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../../Asset/svg/arrow_left_alt.svg';
import { ReactComponent as Search } from '../../../Asset/svg/search.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import { useStyles } from './style';

const JobCandidates = (props: any) => {
  const { push } = useHistory();
  const classes = useStyles();

  const [candidats, setCandidats] = useState<Array<CandidatureTableType>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [seekValue, setSeekValue] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const { id }: any = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchJobConsultant(seekValue);
    fetchTitle();
  }, []);

  const updateValue = debounce(event => {
    const { name, value } = event.target;
    fetchJobConsultant(value);
  }, 1000);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSeekValue(value);
    updateValue(e);
  };

  const onKeyDown = (event: any) => {
    updateValue(event);
  };

  const fetchJobConsultant = async (keyword: string) => {
    try {
      setLoading(true);
      const response = await apiServiceNative.get(`/api/job/search/${id}?query=${keyword}`);
      if (response) {
        setCandidats(transformNeededData(response));
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const fetchTitle = async () => {
    try {
      setLoading(true);
      const response = await apiServiceNative.get(`/api/jobs/${id}?fields[0]=title`);
      if (response) {
        setTitle(response.data.attributes.title);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const transformNeededData = (originalData: Array<CandidatureType>) => {
    return originalData.map((elem: CandidatureType) => {
      return {
        firstname: elem.user.firstname,
        lastname: elem.user.lastname,
        age: elem.user.age,
        genre: elem.user.gender === 1 ? 'M' : 'F',
        ville: elem.user.town,
        diplome: elem.user.diploma,
        phonenumber: elem.user.phone,
        email: elem.user.email,
        avancement: elem.status === 'in_progress' ? 'en cours' : 'terminé',
        idCandidature: elem.id,
      };
    });
  };

  return (
    <JobLayout isaddBackground={true}>
      <ContainerJob>
        <MarginContainer big={true}>
          <div className={classes.btn} onClick={() => history.push('/admin/emploi/annonces')}>
            <ArrowLeft />
            <div style={{ marginLeft: 15 }}>Revenir aux annonces</div>
          </div>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: 20, marginTop: 20 }}>
            <Grid item={true} xs={7}>
              <div>
                <Typography variant="h2" className={classes.title}>
                  Offre : <span>{title}</span>
                </Typography>
                <div className={classes.flexNumber}>
                  <div className={classes.number}>
                    <span>{candidats?.length}</span>
                  </div>
                  <Typography className={classes.textNumber}>Candidat(e)s</Typography>
                </div>
              </div>
            </Grid>
            <Grid
              item={true}
              xs={5}
              style={{ display: 'flex' }}
              alignItems="center"
              justifyContent="flex-end">
              <OutlinedInput
                placeholder="Rechercher"
                type="search"
                className={classes.inputSearch}
                value={seekValue}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                id="outlined-adornment-amount"
                startAdornment={<Search style={{ marginRight: 10 }} />}
              />
              <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
                Inviter un(e) candidat(e)
              </Button>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          <div>
            {loading && <CircularProgress className={classes.loader} />}
            {!loading && candidats && candidats?.length > 0 && (
              <TableCandidat candidats={candidats} loading={loading} />
            )}
            {!loading && candidats?.length === 0 && (
              <Typography className={classes.textNoResult}>Aucun resultat</Typography>
            )}
          </div>
        </MarginContainer>
      </ContainerJob>
    </JobLayout>
  );
};

export default JobCandidates;
