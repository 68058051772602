import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    subtitle: {
      color: '#000A8C',
      fontSize: 26,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      wordWrap: 'break-word',
      textTransform: 'none',
      margin: '20px 0',
    },
    marginTop: {
      marginTop: 15,
    },
  })
);
