import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import OaPopin from '@orientaction/commons/Popin';
import { JobLayout, SimpleModal } from '@orientaction/components';
import JobForm from '@orientaction/components/Form/CreateEditAdmin/Job';
import MatchingForm from '@orientaction/components/Form/CreateEditAdmin/Job/matchingForm';
import useJobForm from '@orientaction/components/Form/CreateEditAdmin/Job/useJobForm';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './style';

const NewJob = () => {
  const { id } = useParams<{ id: string }>();
  const {
    job,
    errors,
    handleChange,
    setJob,
    handleSaveDraft,
    loading,
    loadData,
    handlePopinClose,
    handlePopinConfirm,
    popin,
    handleSubmit,
  } = useJobForm();
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  useEffect(() => {
    if (id && id !== 'new') {
      loadData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const classes = useStyles();

  const saveDraft = () => {
    if (job) {
      handleSaveDraft(id, job, true); // with alert
    }
  };
  const confirmBack = () => {
    if (id === 'new' && job && Object.values(job).filter(j => j && j.length).length > 0) {
      setOpenModal(true);
    } else if (id !== 'new') {
      setOpenModal(true); // may need to check if has change
    } else {
      goBack();
    }
  };
  const goBack = () => {
    history.push('/admin/emploi/annonces');
  };
  return (
    <JobLayout isaddBackground={true}>
      <Grid container={true} className={classes.container}>
        <Grid item={true}>
          <div>
            <div>
              <Button onClick={() => confirmBack()}>
                <Typography variant="h6" className={classes.breadcrumbs}>
                  <ArrowBack fontSize="small" /> Revenir aux annonces
                </Typography>
              </Button>
              <Grid container={true} spacing={2} direction="row" alignItems="center">
                <Grid item={true} xs={1} />
                <Grid item={true} xs={10}>
                  <Typography variant="h2" className={classes.title}>
                    {id === 'new' ? 'Publication d’une annonce' : "Modification d'une annonce"}
                  </Typography>{' '}
                  <p />
                </Grid>
              </Grid>
            </div>

            <div className={classes.root}>
              <form noValidate={true} onSubmit={e => handleSubmit(e, id)}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={1} />
                  <Grid item={true} xs={10}>
                    <Grid
                      container={true}
                      spacing={1}
                      className={clsx(classes.card, classes.marginTop)}>
                      <Grid item={true} xs={12}>
                        <JobForm
                          job={job}
                          handleChange={handleChange}
                          setJob={setJob}
                          errors={errors}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container={true}
                      spacing={1}
                      className={clsx(classes.card, classes.marginTop)}>
                      <Grid item={true} xs={12}>
                        <MatchingForm job={job} handleChange={handleChange} errors={errors} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.footer} container={true} spacing={2}>
                  <Grid item={true} xs={1} />
                  <Grid item={true} xs={5}>
                    <Button
                      type="button"
                      onClick={saveDraft}
                      className={classes.draftButton}
                      variant="outlined"
                      disabled={loading}
                      color="primary">
                      Sauvegarder en brouillon
                    </Button>
                  </Grid>
                  <Grid item={true} xs={5}>
                    <Button
                      className={classes.publishButton}
                      type="submit"
                      color="primary"
                      disabled={loading}>
                      Publier cette annonce
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <OaPopin
        open={popin.open}
        title={popin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={handlePopinClose}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {popin.content}
          </Typography>
        </div>
      </OaPopin>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Êtes vous sur de vouloir quitter l'édition ?"
        textClick="Oui"
        textClose="Non"
        handleClick={goBack}
      />
    </JobLayout>
  );
};

export default NewJob;
