/* eslint-disable react-hooks/exhaustive-deps */
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthLayoutCustom } from '@orientaction/components';
import { getFromLS, isAdmin, isAuthenticated } from '@orientaction/utils';
import { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Left from './Left/Left';
import Right from './Right/Right';

const Login = () => {
  // const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : undefined;
  // if (user) {
  //   const mainLink = isAdmin(user) ? '/admin/users-resultats' : '/main';
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: mainLink,
  //       }}
  //     />
  //   );
  // }
  const history = useHistory();
  const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : undefined;
  useEffect(() => {
    const isValidConnexion = isAuthenticated();
    let mainLink = '/admin/users-resultats';
    if (isAdmin(user)) {
      if (user?.allowed_services) {
        const services = user?.allowed_services.map((i: { id: string }) => i.id);
        if (services.length) {
          if (services.length === 1 && services[0] === 4) {
            mainLink = '/admin/emploi/board';
          }
        }
      }
    } else {
      mainLink = '/main';
    }

    if (isValidConnexion) {
      history.replace(mainLink);
    }
  }, []);

  return <AuthLayoutCustom left={<Left />} right={<Right />} />;
};

const style = makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row-reverse',
  },
  left: {
    position: 'relative',
    flex: 1,
  },
  right: {
    position: 'relative',
    flex: 1,
  },
}));

export default Login;
