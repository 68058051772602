import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  itemParent: {
    display: 'flex',
    borderBottom: '1px solid #F1F1F1',
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  itemLine: {
    flexShrink: 0,
    fontSize: 12,
    color: 'black',
    fontFamily: 'Poppins !important',
    fontWeight: 500,
    margin: 'auto 0px',
    marginRight: 5,
    position: 'relative',
    '&:hover $tooltipLabel': {
      visibility: 'visible',
      opacity: 1,
    },
    '& span': {
      fontFamily: 'Poppins !important',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    '& ::first-letter': {
      textTransform: 'uppercase',
    },
  },
  tooltipLabel: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 800,
    backgroundColor: '#f1f1f1',
    color: 'black',
    padding: '10px 12px 9px',
    borderRadius: 5,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
    zIndex: 1,
    cursor: 'pointer',
    textWrap: 'nowrap',
    width: 'auto !important',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
