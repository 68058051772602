import { FC } from 'react';
import style from './style';

interface IContainerJob {
  children: any;
  rootStyleOverride?: any;
}

const ContainerJob: FC<IContainerJob> = ({ children, rootStyleOverride = undefined }) => {
  const cls = style();

  return <div className={rootStyleOverride || cls.container}>{children}</div>;
};

export default ContainerJob;
