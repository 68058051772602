import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { publicSvgPath } from '../../../utils/constants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh',
      background: '#0E1444',
      borderTopLeftRadius: 60,
      borderTopRightRadius: 60,
    },
    subroot: { width: '100%', height: '100vh', opacity: 0.08, background: 'white' },
    container: { width: 'auto', paddingLeft: '100px', paddingRight: '100px' },
    h3: {
      paddingTop: '30px',
      color: 'white',
      fontSize: 30,
      fontFamily: 'ITC Avant Garde Gothic Std',
      fontWeight: 300,
      wordWrap: 'break-word',
    },
    h3bold: {
      fontWeight: 700,
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      wordWrap: 'break-word',
    },
    hr: { width: '100%', height: '100%', opacity: 0.08, background: 'white' },
    btnTest: {
      width: '100%',
      height: '150px',
      paddingTop: '30px',
      paddingBottom: '30px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 10,
      display: 'block',
      '@media only screen and (max-width: 600px)': {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: 20,
        paddingRight: 20,
        height: '113px',
      },
      '@media only screen and (max-width: 361px)': {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: 20,
        paddingRight: 20,
        height: '113px',
      },
      '& .MuiButton-label': {
        display: 'block !important',
        '@media only screen and (max-width: 600px)': {
          display: 'flex !important',
        },
        '@media only screen and (max-width: 361px)': {
          display: 'flex !important',
        },
      },
    },
    newBtnTest: {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_bouton_faire_un_test.svg)`,
      '@media only screen and (max-width: 600px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_faire_test.svg)`,
      },
      '@media only screen and (max-width: 361px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_faire_test.svg)`,
      },
    },
    newLabelBtn: {
      color: 'white',
      fontSize: '20px',
      paddingLeft: 6,
      fontWeight: 700,
      textAlign: 'center',
      display: 'block',
      marginTop: '5px',
      '@media only screen and (max-width: 600px)': {
        fontSize: '21px',
        display: 'inline',
        marginTop: '0px',
      },
      '@media only screen and (max-width: 361px)': {
        fontSize: '21px',
        display: 'inline',
        marginTop: '0px',
      },
    },
    marginTopTest: {
      marginTop: '23px',
      '@media only screen and (max-width: 600px)': {
        marginTop: '0px',
      },
      '@media only screen and (max-width: 361px)': {
        marginTop: '0px',
      },
    },
    newBtnRes: {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/mes_ressources_fond.svg)`,
      '@media only screen and (max-width: 600px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_ressources.svg)`,
      },
      '@media only screen and (max-width: 361px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_ressources.svg)`,
      },
    },
    imgContainerRes: {
      '@media only screen and (max-width: 600px)': {
        margin: '0px 20px 0px 0px',
      },
      '@media only screen and (max-width: 361px)': {
        margin: '0px 20px 0px 0px',
      },
    },
    newBtnResu: {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/mes_resultats_fond.svg)`,
      '@media only screen and (max-width: 600px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_mes_result.svg)`,
      },
      '@media only screen and (max-width: 361px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_mes_result.svg)`,
      },
    },
    imgResuResp: {
      '@media only screen and (max-width: 600px)': {
        width: '25% !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '25% !important',
      },
    },
    grid: {
      paddingTop: 50,
    },
    presenterContainer: {
      flexGrow: 1,
    },
    presenterImage: {
      marginTop: 50,
      zIndex: 1900,
    },
    presenterCard: {
      marginTop: 100,
      backgroundColor: '#151528',
      color: '#FFF',
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '180%',
    },
    presenteAuthor: {
      fontWeight: 700,
    },
    presenterText: {
      minHeight: 350,
    },
    i: {
      fontSize: 14,
      fontStyle: 'italic',
    },
  })
);
