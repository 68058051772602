import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      width: 'calc(100% - 150px)',
      padding: '40px 75px 150px',
    },
    body: {
      borderTopRightRadius: 50,
      borderTopLeftRadius: 50,
      minHeight: '50vh',
      background: '#F7F7FB',
      paddingBottom: 50,
    },
    containerProfile: {
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    },
    imgProfile: {
      display: 'block',
      width: 88,
      height: 88,
      objectFit: 'cover',
      borderRadius: '50%'
    },
    nameProfile: {
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      fontSize: 20,
      color: 'white',
      marginLeft: 20,
      '& div': {
        fontFamily: 'Poppins',
        fontSize: 14,
        color: 'white',
      },
    },
    cellule: {
      padding: '10px',
    },
    cellulename: {
      color: '#FFF',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 300,
      '& div': {
        fontSize: '16px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    containerSection: {
      width: '80%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 20,
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: '#B0B5FF',
      textTransform: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      width: 'fit-content',
      marginBottom: 20,
    },
  })
);
