/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath, imgBook2 } from '../../../../utils/constants';
import { cloneDeep, debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';

const Page29 = (props: any) => {
  const classe = useStyles();
  const { idEbook } = props;
  const ebookRecVal = useSelector(ebookRecValues);
  const [response, setResponse] = useState<string>('');

  useEffect(() => {
    if (ebookRecVal) {
      setResponse(ebookRecVal?.salary || '');
    }
  }, [ebookRecVal]);
  const updateValue = debounce((event) => {
    handleChangeGlobalState(event);
  }, 1000);

  const onKeyDown = (event: any) => {
    if (props.readOnly) return;
    updateValue(event);
  };

  const handleChange = (e: any) => {
    if (props.readOnly) return;
    updateValue(e);
  };

  const handleChangeGlobalState = async (e: any) => {
    if (props.readOnly) return;
    const { value } = e.target;
    setResponse(value);
    const resp = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        salary: value,
      },
    });
  };
  return (
    <Page id={29}>
      <br />
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            QUELLE EST LA RÉMUNÉRATION ANNUELLE <br />
            BRUTE QUE VOUS SOUHAITEZ POUR VOTRE <br />
            ACTIVITÉ PROFESSIONNELLE ?
          </Typography>
          <Paragrapth align="justify" colorBlue={true}>
            <strong>
              Merci d’indiquer le chiffre en millers d’euros (par exemple 30 000 euros).
            </strong>
          </Paragrapth>
        </div>
      </div>
      <div className={classe.trait00} />
      <div style={{ marginBottom: 40 }}>
        <input
          name="name"
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onBlur={handleChangeGlobalState}
          placeholder="Ex : 30 000"
          style={{
            width: '99%',
            height: '40px',
            fontSize: '25px',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
          }}
          className={classe.borderText}
          defaultValue={response}
          type="number"
        />
      </div>
      <img
        src={`${publicBookPath}/intendante.png`}
        alt="photo"
        className={classe.imgNeedFull}
        style={{ margin: 'auto', width: '80%' }}
      />
    </Page>
  );
};
export default Page29;
