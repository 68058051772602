/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { hardSkills } from '@orientaction/utils';
import clsx from 'clsx';
import { TableAspiration2, Page, Paragrapth } from '@orientaction/components';
import concat from 'lodash/concat';
import chunck from 'lodash/chunk';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { useEffect, useState } from 'react';

const Page47 = (props: any) => {
  const classes = useStyles();
  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  const [defaultChecked, setDefaultChecked] = useState<any>([]);
  useEffect(() => {
    if (ebookRecVal) {
      setDefaultChecked(ebookRecVal?.softwareTools ? JSON.parse(ebookRecVal?.softwareTools || "") : []);
    }
  }, [ebookRecVal]);

  const handleChangeValuesChecked = async (dataToPut: any) => {
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        softwareTools: JSON.stringify(dataToPut),
      },
    });
  };
  return (
    <Page id={47}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          gutterBottom={true}
          className="opacity07">
          MAÎTRISE DES OUTILS INFORMATIQUES
        </Typography>
      </div>
      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: 30, marginBottom: 30 }}
      />
      <Paragrapth align="center">
        Cochez les cases qui correspondent aux logiciels informatiques que vous maîtrisez. (5
        maximum){' '}
      </Paragrapth>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableAspiration2 figure32={hardSkills} defaultChecked={defaultChecked} handleChangeValuesChecked={handleChangeValuesChecked} />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
  })
);

export default Page47;
