/* eslint-disable react-hooks/exhaustive-deps */
import { Button, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { ApiActions, apiState } from '@orientaction/api-actions';
import { BurgerMenu, ProfileMenu, UserPhotos } from '@orientaction/commons';
import { Footer, SimpleModalTest, TabLayout } from '@orientaction/components';
import { useAlert, useLanguage, useProfileMenu } from '@orientaction/hooks';
import { userService } from '@orientaction/services';
import {
  getFromLS,
  isAdmin,
  isRootUser,
  publicLogoPath,
  publicSvgPath,
  updateUserInfoInLS,
} from '@orientaction/utils';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Boutique } from '../../Asset/svg/boutique.svg';
import { getMe } from '../../services/user.service';
import style from './style';

const Layout = ({
  children,
  isaddBackground,
  noPadding,
  classname,
  isNew,
  isNew2,
  showLink = true,
  footer,
  isNew3,
  isClassic,
  showOverFlow = false,
  useHeader = true,
}: any) => {
  const history = useHistory();
  const alert = useAlert();
  const { language, onLanguageChange } = useLanguage();
  const cls = style();
  const user = JSON.parse(getFromLS('userInfos') || '{}');
  const { open, anchorRef, handleToggle, handleClose, goToProfileEdit, logout } = useProfileMenu({
    user,
  });
  const mainLink = isAdmin(user) ? '/admin/users-resultats' : '/main';
  const dispatch = useDispatch();

  const redirectUser = () => {
    dispatch(ApiActions.setTabLayout(0 as any));
    if (user) {
      history.push(mainLink);
    } else {
      history.push('/');
    }
  };

  const switchRole = async () => {
    try {
      const isSecondRoleActivated = user?.company_user_role?.id === 4;
      const token = getFromLS('userToken') || '';
      const { data } = await userService.switchRole(token, !isSecondRoleActivated);
      localStorage.setItem('userInfos', JSON.stringify(data));
      const redirectPath = data?.company_user_role?.id === 5 ? '/main' : '/admin/users-resultats';
      history.push(redirectPath);
    } catch (error) {
      alert.openSimpleAlert();
    }
  };

  return (
    <div className={clsx(cls.layout, classname)}>
      <div
        className={clsx(
          cls.content,
          isaddBackground ? cls.grayBG : null,
          noPadding ? cls.noPadding : null,
          isNew ? cls.imageBG : null,
          isNew2 ? cls.imageBG2 : null,
          isNew3 ? cls.imageBG3 : null,
          isClassic ? cls.imageClassic : null
        )}>
        {useHeader && (
          <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>
            <nav className={cls.navbar}>
              <div className={cls.flexContainer}>
                <div className={cls.logoContainer} onClick={redirectUser}>
                  <picture className={cls.logo}>
                    <source srcSet={`${publicLogoPath}/e-orientaction.png`} />

                    <img
                      src={`${publicLogoPath}/e-orientaction.png`}
                      alt="logo-orientation"
                      className={cls.logoImg}
                    />
                  </picture>

                  {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
                </div>

                <Hidden only={['xs', 'sm']}>
                  <div style={{ marginLeft: 25 }}>
                    {showLink ? (
                      <TabLayout
                        service={user?.service}
                        isRoot={isRootUser(user)}
                        isAdmin={isAdmin(user)}
                      />
                    ) : (
                      <div style={{ padding: 40 }} />
                    )}
                  </div>
                </Hidden>
              </div>
              <div className={cls.profilContainer}>
                <Hidden only={['xs', 'sm', 'md']}>
                  {!isAdmin(user) && language !== 'GB' && (
                    <Button
                      onClick={() => window.open('https://orientaction.kneo.me/home', '_blank')}
                      color="primary"
                      size="large"
                      className={cls.btnBoutique}
                      startIcon={<Boutique />}>
                      Boutique
                    </Button>
                  )}
                </Hidden>
                <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                  {!isAdmin(user) && language !== 'GB' && (
                    <IconButton
                      onClick={() => window.open('https://orientaction.kneo.me/home', '_blank')}
                      color="primary"
                      className={cls.btnBoutique}>
                      <Boutique />
                    </IconButton>
                  )}
                </Hidden>
                {!isAdmin(user) && user && (
                  <ReactFlagsSelect
                    className={cls.flagContainer}
                    selectButtonClassName={cls.flagSelectButton}
                    countries={['FR', 'GB']}
                    showOptionLabel={false}
                    showSelectedLabel={false}
                    fullWidth={false}
                    selected={language}
                    onSelect={onLanguageChange}
                  />
                )}
                <Hidden only={['xl', 'lg', 'md']}>
                  <BurgerMenu
                    isAdmin={isAdmin(user)}
                    menu={['Résultats des tests', 'Gestion & Administration']}
                    goToProfileEdit={goToProfileEdit}
                    logout={logout}
                    service={user?.service}
                  />
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                  {user?.allowed_services &&
                    user?.allowed_services.findIndex((i: any) => i.id === 4) > -1 && (
                      <div>
                        <Button
                          onClick={() => history.replace('/admin/emploi/board')}
                          className={cls.basculeButton}>
                          Voir l’espace recrutement
                        </Button>
                      </div>
                    )}
                  <UserPhotos
                    anchorRef={anchorRef}
                    user={user}
                    handleToggle={handleToggle}
                    open={open}
                  />
                  <ProfileMenu
                    open={open}
                    anchorRef={anchorRef}
                    handleToggle={handleToggle}
                    handleClose={handleClose}
                    goToProfileEdit={goToProfileEdit}
                    logout={logout}
                    user={user}
                    switchRole={switchRole}
                  />
                </Hidden>
              </div>
            </nav>
          </AppBar>
        )}

        <div className={cls.children} style={{ overflow: showOverFlow ? 'visible' : 'auto' }}>
          {children}
          {footer && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
