import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      color: '#000A8C',
      fontSize: 26,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      wordWrap: 'break-word',
      textTransform: 'none',
      margin: '20px 0',
    },
    marginTop: {
      marginTop: 15,
    },
    label: {
      color: '#151528',
      fontSize: 14,
      wordWrap: 'break-word',
      fontFamily: 'Open Sans',
      fontWeight: 500,
      lineHeight: 1.5,
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
      '& textarea': {
        '&::placeholder': {
          color: '#9C9DB7',
        },
      },
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247',
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    errorColor: {
      color: 'red',
    },
    errorText: {
      color: 'red',
      fontSize: 11,
    },
    chips: {
      '& .MuiAutocomplete-tag': {
        borderRadius: '5px',
      },
      '& .MuiChip-label': {
        paddingTop: '3px',
      },
    },
  })
);
