// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { publicSvgPath } from '../../utils/constants';
import clsx from 'clsx';
import CircularProgressCustom from '../CircularProgressCustom';
import { useStyles } from './style';
import { useMemo } from 'react';

const CardMatching = (props: any) => {
  const classes = useStyles();
  const { value, title } = props;

  const borderColor = useMemo(() => {
    if (value >= 0 && value <= 14) {
      return '#E63C3C';
    }
    if (value >= 15 && value <= 29) {
      return '#FFA800';
    }
    if (value >= 30 && value <= 74) {
      return '#FFDC37';
    }
    if (value >= 75 && value <= 100) {
      return '#000A8C';
    }
  }, [value])

  return (
    <Card variant="outlined" className={classes.cardContainer} style={{ borderColor }}>
      <Grid container={true} spacing={3} alignItems="center">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgressCustom
            value={value}
            color={borderColor}
            size={60}
            fontSize={20}
            fontSizeFloat={20}
            isPercent={true}
          />
          <Typography className={classes.matchTitle}>Matching global</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <span className={classes.trait} />
        </Grid>
        <Grid item={true} xs={7}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
export default CardMatching;
