import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
  })
);
