/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';
import { ReactComponent as M4 } from '../../../../Asset/svg/matching4.svg';
import { useHistory } from 'react-router-dom';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const MatchingTalent = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const talent = ['intéraction', 'imagination', 'empathie', 'action', 'adaptation'];

  return (
    <div className={classes.containerMatch}>
      <Grid container={true} alignItems="center" justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <M4 style={{ width: 30, height: 33, marginRight: 15 }} />
          <Typography className={classes.matchTitle}>
            Matching <span>Talents</span>
          </Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <CircularProgressCustom
            value={60}
            color="#BC72A7"
            size={40}
            fontSize={12}
            fontSizeFloat={12}
            isPercent={true}
          />
        </Grid>
      </Grid>
      {/* 16 soft skills Harmony® */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Test des 16 soft skills Harmony®</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès résultats
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* body */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px solid #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Gestion du stress
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={8}
          style={{
            borderTop: '1px solid #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleMatch}>
              70/100
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Audace
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={8}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleMatch}>
              70/100
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Sens du collectif
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={8}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleMatch}>
              70/100
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Gestion du temps
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={8}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleMatch}>
              70/100
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Créativité
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={8}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleMatch}>
              70/100
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* 16 soft skills Harmony® */}

      <div className={classes.separator} />

      {/* Autoévaluation Hard skills */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Autoévaluation Hard skills</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès ebook
          </Button>
        </Grid>
      </Grid>
      <Grid
        style={{
          marginTop: 20,
          borderTop: '1px solid #5E65A3',
          borderBottom: '1px solid #5E65A3',
          borderRight: '1px solid #5E65A3',
          borderLeft: '1px solid #5E65A3',
        }}
        container={true}
        item={true}>
        {/* body 1 */}
        <Grid
          item={true}
          lg={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Langues parlées
            </Typography>
          </div>
        </Grid>
        <Grid item={true} container={true} lg={8}>
          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>
        </Grid>
        {/* body 1 */}

        {/* body 2 */}
        <Grid
          item={true}
          lg={4}
          style={{ display: 'flex', alignItems: 'center', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Logiciels maîtrisés
            </Typography>
          </div>
        </Grid>
        <Grid item={true} container={true} lg={8}>
          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid
            item={true}
            lg={6}
            style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6} style={{ borderBottom: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>

          <Grid item={true} lg={6} style={{ borderRight: '1px solid #5E65A3' }}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Audace
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={6}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleMatch}>
                70/100
              </Typography>
            </div>
          </Grid>
        </Grid>
        {/* body 2 */}
      </Grid>
      {/* Autoévaluation Hard skills */}
    </div>
  );
};

export default MatchingTalent;
