import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    background: '#FAFAFA',
    borderRadius: 5,
    // width: '100%',
    width: '65%',
    height: '100%',
    maxWidth: 1280,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  blockContent: {
    position: 'relative',
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      minHeight: 'auto',
    },
    '& *': {
      fontFamily: 'Poppins',
    },
  },
  blockContentImg: {
    padding: 20,
    position: 'relative',
    minHeight: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      minHeight: 'auto',
    },
  },
  title: {
    color: '#272727',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  titleType: {
    color: '#272727',
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    textTransform: 'none',
    marginRight: 15,
  },
  blockType: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blockTypeInside: {
    display: 'flex',
    alignItems: 'center',
  },
  blockText: {
    width: '100%',
    minHeight: 'auto',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      height: 'auto',
    },
  },
  blockText2: {
    width: '100%',
    minHeight: 'auto',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      height: 'auto',
    },
  },
  select: {
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: 500,
  },
  containerChoice: {
    width: '100%',
    height: '100%',
  },
  blockContentParent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  choice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    width: '100%',
    height: '100%',
  },
  iconBtnImage: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '-3%',
    right: '-3%',
  },
  icon: {
    width: '14px',
    height: '14px',
  },

  titleEndGame: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#272727',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '5px',
  },
  img: {
    width: '100%',
    // height: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
  blockContentTextNormal: {
    color: '#151528',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    wordBreak: 'break-word',
    '& *': {
      fontFamily: 'Poppins !important',
    },
    '& h5': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      color: '#151528 !important',
      background: 'transparent !important',
      '& *': {
        color: '#151528 !important',
        background: 'transparent !important',
      },
    },
  },
  editor: {
    fontSize: '18px',
    div: {
      fontSize: '18px',
    },
    h2: {
      fontSize: '27px',
    },
  },

  choiceTitle: {
    color: '#000A8C',
    fontFamily: 'Poppins',
    fontSize: '21px',
    fontStyle: 'italic',
    fontWeight: 700,
    lineheight: 'normal',
    marginBottom: 15,
  },
  choiceEndGame: {
    color: '#E63C3C',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 700,
    lineheight: '20px',
    textAlign: 'center',
  },
  btn: {
    fontSize: 14,
    textTransform: 'none',
    color: 'white',
    padding: '8px 15px 8px 15px',
    marginTop: 25,
    boxShadow: 'none',
    '& .MuiButton-label': {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  btnValider: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '25px',
  },
  btnEnd: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    background: '#F1F1F1',
    padding: 35,
  },
  btnicon: {
    width: 13,
    height: 'auto',
  },
  blockTextLong: {
    width: '100%',
    minHeight: 'auto',
  },
  separatorCon: {
    '&:after': {
      content: '',
      height: '1px',
      width: '100%',
      background: '#E1E1E1',
      display: 'block',
    },
  },
  flexDirectionReverse: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  btnPursue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: 35,
  },
  btnPursueInside: {
    fontSize: 14,
    textTransform: 'none',
    color: 'white',
    boxShadow: 'none',
    height: 'auto',
    padding: '4px 15px',
    '& .MuiButton-label': {
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& *': {
      color: 'white !important',
      background: 'transparent !important',
    },
  },
  btnFloat: {
    position: 'absolute',
    top: 15,
    right: 15,
    visibility: 'hidden',
    [theme.breakpoints.down('xs')]: {
      visibility: 'visible',
    },
  },
  containerGrid: {
    // padding: '45px 160px',
    padding: '45px 120px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  imgContainer: {
    width: '100%',
    // height: '60vh',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  }
}));
