/* eslint-disable no-undef */
import { Collapse, Grid, Typography, Theme, useMediaQuery } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { useStyles } from './style';
import clsx from 'clsx';
import { TableCandidat } from '@orientaction/components';
import { apiServiceNative } from '../../utils/fetchNative';

const ExpandableItemJob = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { data, query } = props;
  const [candidats, setCandidats] = useState<Array<CandidatureTableType>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [numberCandidat, setNumberCandidat] = useState<number>(data.candidatures);

  const openTab = async () => {
    setOpen(!open);
    try {
      if (!open) {
        setLoading(true);
        await fetchData();
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    const response = await apiServiceNative.get(`/api/job/search/${data.id}?query=${query}`);
    if (response) {
      const dataToShow = transformNeededData(response);
      setCandidats(dataToShow);
    }
  };

  const transformNeededData = (originalData: Array<CandidatureType>) => {
    return originalData.map((elem: CandidatureType) => {
      return {
        firstname: elem.user.firstname,
        lastname: elem.user.lastname,
        age: elem.user.age,
        genre: elem.user.gender === 1 ? 'M' : 'F',
        ville: elem.user.town,
        diplome: elem.user.diploma,
        phonenumber: elem.user.phone,
        email: elem.user.email,
        avancement: elem.status === 'in_progress' ? 'en cours' : 'terminé',
        idCandidature: elem.id,
        candidature: elem,
      } as CandidatureTableType;
    });
  };

  const handleToDelete = async (id: number) => {
    setLoading(true);
    try {
      const response = await apiServiceNative.delete(`/api/candidatures/${id}`);
      if (response) {
        await fetchData();
        setNumberCandidat((oldValue: number) => oldValue - 1);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <div className={classes.itemRoot} key={data?.id}>
      <Grid
        container={true}
        className={classes.titleRoot}
        onClick={openTab}
        style={{ borderRadius: open ? '5px 5px 0px 0px' : '5px' }}>
        <Grid item={true} xs="auto" md="auto">
          <div className={classes.number}>
            <span>{numberCandidat}</span>
          </div>
        </Grid>
        <Grid item={true} xs={true} md={true}>
          <div className={classes.itemExp}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.textBulle}>
              {data?.title}
            </Typography>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                zIndex: 3,
                position: 'relative',
              }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </span>
          </div>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <TableCandidat candidats={candidats} loading={loading} handleToDelete={handleToDelete} />
      </Collapse>
    </div>
  );
};

export default ExpandableItemJob;
