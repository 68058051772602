/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line simple-import-sort/imports
import { Button, Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from '../../style';
import { publicBookPath } from '../../../../utils/constants';

const Page56 = (props: any) => {
  const classe = useStyles();
  return (
    <Page id={56}>
      <TitlePage>POUR AUGMENTER VOS CHANCES DE RÉUSSIR, FAITES-VOUS ACCOMPAGNER</TitlePage>
      <br />
      <br />
      <br />
      <Paragrapth align="justify">
        Si vous souhaitez un accompagnement personnalisé dans votre recherche d’emploi,
        ORIENTACTION, la branche du groupe spécialisée dans l’accompagnement des évolutions
        professionnelles, est à votre disposition.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Notre équipe dédiée peut vous offrir des conseils, des orientations et un soutien adapté à
        vos besoins individuels. Nos services comprennent différentes possibilités et prestations
        qui peuvent vous aider à réussir :
      </Paragrapth>
      <div className={classe.containerListCompass} style={{ paddingLeft: 15, marginTop: 40 }}>
        <div className={classe.marginListCompass}>• Bilan de compétences</div>
        <div className={classe.marginListCompass}>• Outplacement complet,</div>
        <div className={classe.marginListCompass}>• Coaching CV et lettres de motivation,</div>
        <div className={classe.marginListCompass}>
          • Coaching préparation aux entretiens de recrutement,
        </div>
        <div className={classe.marginListCompass}>
          • Élaboration d’un plan de formation individualisé pour booster votre profil.
        </div>
      </div>
      <Grid container={true}>
        <Grid item={true} xs={12} sm={6}>
          <img src={`${publicBookPath}/talk.jpg`} alt="photo" className={classe.imgNeedFull} />
        </Grid>
        <Grid item={true} xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: '#2B56A5' }}
            className={classe.fixedBtn}>
            <span style={{ color: 'white' }}>Nous contacter</span>
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};
export default Page56;
