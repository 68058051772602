/* eslint-disable no-undef */
import type { AutocompletionRequest, PredictionOption } from './types';

export const autocompletionRequestBuilder = (
  autocompletionRequest: AutocompletionRequest,
  input: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken
): google.maps.places.AutocompletionRequest => {
  const { bounds, location, ...rest } = autocompletionRequest;

  const res: google.maps.places.AutocompletionRequest = {
    input,
    ...rest,
  };

  if (sessionToken) {
    res.sessionToken = sessionToken;
  }

  if (bounds) {
    res.bounds = new google.maps.LatLngBounds(...bounds);
  }

  if (location) {
    res.location = new google.maps.LatLng(location);
  }

  return res;
};
export const buildPlaceholderOption = (placeDescription: string): PredictionOption => ({
  description: placeDescription,
  matched_substrings: [],
  place_id: '',
  structured_formatting: {
    main_text: '',
    main_text_matched_substrings: [],
    secondary_text: '',
  },
  terms: [],
  types: [],
});
