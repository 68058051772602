/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { LayoutResultat } from '@orientaction/layouts';
import { kebabCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OrientButton from '../../commons/OrientButton';
import useTest from '../../hooks/useTest';
import style from './style';
import { getMe } from '../../services/user.service';
import { getFromLS } from '@orientaction/utils';
import { apiServiceNative } from '../../utils/fetchNative';

const TestPresentation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getTestPresentation, test, loadPage } = useTest();
  const [testsToDo, setTestsToDo] = useState<any>([]);
  const [testsToSee, setTestToSee] = useState<any>([]);
  const [loadingMe, setLoadingMe] = useState<boolean>(false);

  const history = useHistory();

  const dataParams: any = useParams();

  const token = getFromLS('userToken') || '';
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  useEffect(() => {
    const fetchMe = async () => {
      setLoadingMe(true);
      const dataMe = await getMe(token);
      if (dataMe && dataMe.data) {
        setTestsToDo([...dataMe.data.tests_to_do]);
        setTestToSee([...dataMe.data.tests_to_see_result].map((t) => t.id));
      }
      setLoadingMe(false);
    };
    if (token) {
      fetchMe();
    }
    getTestPresentation(dataParams.id);
  }, []);

  const classes = style();

  const gotTo = async () => {
    if (token) {
      const dataTestToRemove = testsToDo
        .filter((el: any) => el.id !== parseInt(dataParams.id, 10))
        .map((t: any) => t.id);
      const dataTestToStore = [...testsToSee, parseInt(dataParams.id, 10)];
      const response = await apiServiceNative.put(`/api/users/${userConnected.id}`, {
        tests_to_do: dataTestToRemove,
        tests_to_see_result: dataTestToStore,
      });
    }
    history.push(`/test/${test.id}/passer/${kebabCase(test.attributes.title)}`);
  };

  return (
    <LayoutResultat
      showBottomLines={!isMobile}
      showToplines={false}
      isGradient={true}
      isNew2={true}>
      {loadPage || loadingMe ? (
        <div className={classes.bodycenter}>
          <CircularProgress style={{ color: 'white' }} />
        </div>
      ) : (
        <div className={classes.root}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => history.push('/user/tests')}>
            Retour
          </Button>
          <div>
            <Grid container={true} justifyContent="center">
              <Grid className={classes.testStartingImgContainer} item={true}>
                <img
                  className={classes.imageResponsive}
                  src={test.attributes ? test.attributes.image : ''}
                  alt="pic"
                />
              </Grid>
              <Grid item={true} md={4}>
                <Grid container={true} justifyContent="center">
                  <Grid
                    item={true}
                    xs={11}
                    md={10}
                    sm={10}
                    style={{ textAlign: 'left', color: '#FFFFFF', fontSize: '15px' }}>
                    <h1
                      className={classes.title}
                      style={{ fontFamily: 'Poppins', marginTop: 0, marginBottom: 0 }}>
                      {test.attributes ? test.attributes.title : 'En attente...'}
                    </h1>
                  </Grid>
                  <Grid
                    item={true}
                    xs={11}
                    md={10}
                    sm={10}
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <AccessTimeIcon fontSize="small" style={{ color: 'white' }} />
                    <span style={{ marginLeft: 10, color: 'white' }}>
                      Durée du test : {test.attributes.time || '   '} min
                    </span>
                  </Grid>
                  <Grid
                    item={true}
                    xs={11}
                    md={10}
                    sm={10}
                    style={{
                      textAlign: 'left',
                      color: '#FFFFFF',
                      fontSize: '14px',
                      fontWeight: 400,
                      zIndex: 999,
                    }}>
                    <p
                      className={classes.text}
                      dangerouslySetInnerHTML={{
                        __html: test.attributes ? test.attributes.description : 'En attente...',
                      }}
                    />
                  </Grid>

                  <Grid
                    item={true}
                    xs={11}
                    md={10}
                    sm={10}
                    style={{ marginTop: 20, marginBottom: 5 }}>
                    <OrientButton
                      className={classes.buttonResponsive}
                      style={{ paddingLeft: 20, paddingRight: 20 }}
                      onClick={() => gotTo()}>
                      C'est parti !
                    </OrientButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </LayoutResultat>
  );
};

export default TestPresentation;
