/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid } from '@material-ui/core';
import { useStyles } from './style';
import { ReactComponent as Sorticon } from '../../Asset/svg/sort.svg';
import ItemCandidat from './ItemCandidat';
import { useMemo, useState } from 'react';
import { cloneDeep, findIndex, orderBy, sortBy } from 'lodash';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Ihead, StatusOrderEnum } from '../../Type/type.d';

interface ITableCandidatProps {
  candidats: CandidatureTableType[];
  loading: boolean;
  handleToDelete?: (id: number) => any;
}

const TableCandidat = ({ candidats, loading, handleToDelete }: ITableCandidatProps) => {
  const classes = useStyles();
  const headers: Array<Ihead> = [
    {
      name: 'Prénoms',
      xs: '8%',
      sort: true,
      field: 'firstname',
    },
    {
      name: 'Nom',
      xs: '7%',
      sort: true,
      field: 'lastname',
    },
    {
      name: 'Genre',
      xs: '5%',
      sort: true,
      field: 'genre',
    },
    {
      name: 'Âge',
      xs: '5%',
      sort: true,
      field: 'age',
    },
    {
      name: 'Diplome',
      xs: '7%',
      sort: true,
      field: 'diplome',
    },
    {
      name: 'Téléphone',
      xs: '8%',
      sort: false,
    },
    {
      name: 'Email',
      xs: '9%',
      sort: false,
    },
    {
      name: 'Ville',
      xs: '7%',
      sort: true,
      field: 'ville',
    },
    {
      name: 'Matching effectué',
      xs: '15%',
      sort: false,
    },
    {
      name: 'Avancement',
      xs: '9%',
      sort: true,
      field: 'avancement',
    },

    {
      name: 'Matching global',
      xs: '10%',
      sort: false,
    },
    {
      name: 'Action',
      xs: '10%',
      sort: false,
    },
  ];

  const [elemToSort, setElemToSort] = useState<Array<any>>([]);
  const [headersD, setHeadersD] = useState<Array<Ihead>>(headers);
  const [idDeleted, setIdDeleted] = useState<number>();
  const candidatSorted = useMemo(() => {
    let candidatsToFitler = cloneDeep(candidats);
    if (idDeleted) {
      candidatsToFitler = candidatsToFitler.filter(
        (cand: CandidatureTableType) => cand.idCandidature !== idDeleted
      );
    }
    return orderBy(
      candidatsToFitler,
      elemToSort.map(el => el.elem),
      elemToSort.map(el => el.type)
    );
  }, [candidats, elemToSort, idDeleted]);

  const handleSort = (elem: string, index: number) => {
    const found = findIndex(elemToSort, function (o) {
      return o.elem === elem;
    });
    const dataCloned = cloneDeep(elemToSort);
    const headersCloned = cloneDeep(
      headersD.map((elem, ind) => {
        if (index !== ind) {
          elem.type = undefined;
        }
        return elem;
      })
    );
    dataCloned[0] = {
      elem,
      type:
        StatusOrderEnum.asc === headersCloned[index]?.type
          ? StatusOrderEnum.desc
          : StatusOrderEnum.asc,
    };
    headersCloned[index].type =
      StatusOrderEnum.asc === headersCloned[index]?.type
        ? StatusOrderEnum.desc
        : StatusOrderEnum.asc;
    setElemToSort(dataCloned);
    setHeadersD(headersCloned);
    console.log({ dataCloned, headersCloned });
  };

  const handleIdToDelete = (id: number) => {
    setIdDeleted(id);
    if (handleToDelete) {
      handleToDelete(id);
    }
  };

  return (
    <div>
      <div className={classes.gridParent}>
        {headersD.map((elem: any, index: number) => (
          <div key={index} style={{ width: elem.xs }}>
            <div
              className={classes.titleContainer}
              style={elem.name === 'Action' ? { justifyContent: 'center' } : {}}>
              <p className={classes.title}>{elem.name}</p>
              {elem.sort && (
                <div onClick={() => elem.sort && handleSort(elem.field, index)}>
                  {(elem.type === undefined || elem.type === null) && (
                    <Sorticon className={classes.icon} />
                  )}
                  {elem.type === StatusOrderEnum.asc && (
                    <ArrowDownwardIcon className={classes.iconM} />
                  )}
                  {elem.type === StatusOrderEnum.desc && (
                    <ArrowUpwardIcon className={classes.iconM} />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.bodyList}>
        {loading && <CircularProgress className={classes.loader} />}
        {!loading &&
          candidatSorted &&
          candidatSorted.map((candidat: any) => (
            <ItemCandidat
              candidat={candidat}
              dataColumns={headers.map(elem => elem.xs)}
              handleIdToDelete={handleIdToDelete}
            />
          ))}
      </div>
    </div>
  );
};
export default TableCandidat;
