/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContainerJob, MarginContainer } from '@orientaction/commons';
import { JobLayout, TableAnnonce } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import dateFormat from 'dateformat';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Search } from '../../../Asset/svg/search.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import { useStyles } from './style';

const Job = (props: any) => {
  const { push } = useHistory();
  const classes = useStyles();

  const [dataAnnonces, setDataAnnonces] = useState<Array<JobTableType>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [seekValue, setSeekValue] = useState<string>('');
  const user = JSON.parse(getFromLS('userInfos') || '{}');
  const [pagination, setPagination] = useState<any>(null);
  const PAGE_SIZE = 10;

  useEffect(() => {
    fetchJobConsultant(seekValue);
  }, []);

  const updateValue = debounce(event => {
    const { name, value } = event.target;
    fetchJobConsultant(value);
  }, 1000);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSeekValue(value);
    updateValue(e);
  };

  const onKeyDown = (event: any) => {
    updateValue(event);
  };

  const fetchJobConsultant = async (keyword: string, page: number = 1) => {
    try {
      setLoading(true);
      const seekResp = await apiServiceNative.get(
        `/api/jobs/consultant/${user.id}?query=${keyword}&page=${page}&pageSize=${PAGE_SIZE}`
      );
      if (seekResp && seekResp.data) {
        setDataAnnonces(transformNeededData(seekResp.data));
        setPagination(seekResp.pagination);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const transformNeededData = (originalData: Array<JobType>) => {
    return originalData.map((elem: JobType) => {
      return {
        title: elem.title,
        adress: elem.addressCity,
        date: elem.publishedAt ? dateFormat(elem.publishedAt, 'dd/mm/yyyy à HH:MM:ss') : '',
        status: elem.publishedAt ? 'Publié' : 'En brouillon',
        candidats: elem.candidatures.length,
        id: elem.id,
      };
    });
  };
  const handleChangePagination = (event: React.MouseEvent<HTMLButtonElement>, newpage: number) => {
    fetchJobConsultant(seekValue, newpage + 1);
  };

  return (
    <JobLayout isaddBackground={true}>
      <ContainerJob>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: '20px' }}>
            <Grid item={true}>
              <Typography variant="h2" style={{ textTransform: 'none' }}>
                Vos annonces
              </Typography>
            </Grid>
            <Grid item={true} style={{ display: 'flex' }} alignItems="center">
              <OutlinedInput
                placeholder="Rechercher"
                type="search"
                className={classes.inputSearch}
                value={seekValue}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                id="outlined-adornment-amount"
                startAdornment={<Search style={{ marginRight: 10 }} />}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.btnPrimarySeek}
                onClick={() => push('/admin/emploi/annonces/new')}>
                Créer une annonce
              </Button>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          <div>
            {loading && <CircularProgress className={classes.loader} />}
            {!loading && dataAnnonces && dataAnnonces?.length > 0 && (
              <TableAnnonce
                annonces={dataAnnonces}
                onReload={() => fetchJobConsultant(seekValue)}
                pagination={pagination}
                handleChangePagination={handleChangePagination}
              />
            )}
            {!loading && dataAnnonces?.length === 0 && (
              <Typography className={classes.textNoResult}>Aucun resultat</Typography>
            )}
          </div>
        </MarginContainer>
      </ContainerJob>
    </JobLayout>
  );
};

export default Job;
