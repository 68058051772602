/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  GridProps,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { StarOutlineRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { FC, useEffect, useRef, useState } from 'react';
import { IJob, IJobMatching, MatchingKey } from '../type';
import { useStyles } from './style';
import { IMachingData } from './type';

type MatchingFormProps = {
  id: MatchingKey;
  job: IJobMatching;
  title: string;
  color: string;
  items: { [key: string]: IMachingData };
  errors: { [key: string]: any };
  gridItemProps?: GridProps;
  handleChange?: (e: { target: { name: string; value: any } }) => void;
};

const MatchingSection: FC<MatchingFormProps> = ({
  id,
  job,
  color,
  title,
  items: mainItems,
  handleChange,
  errors = {},
  gridItemProps = {},
}) => {
  const classes = useStyles();
  const [itemsToUse, setItems] = useState<{ [key: string]: IMachingData }>(cloneDeep(mainItems));
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const [outilsChecked, setChecked] = useState<string[]>([]);

  const handleOnChange = (key: string, name = '', value: any) => {
    if (mainItems[key].dependency) {
      const newVals = { ...(values[key] ?? {}), [name]: value };
      setItems(prev => {
        const optionsUpdated = cloneDeep(mainItems)[key].items.map(item => {
          if (item.name !== name && item.name) {
            Object.keys(newVals).forEach(k => {
              const v = newVals[k];
              if (item.name !== k && item.options) {
                const index = item.options.indexOf(v);
                item.options.splice(index, 1);
              }
            });
          }
          return item;
        });
        prev[key].items = optionsUpdated;
        return { ...prev };
      });
    }
    const newVal = {
      ...values,
      [key]: {
        ...(values[key] as IObjectOfString),
        [name]: value,
      },
    };
    setValues(newVal);
    if (handleChange) handleChange({ target: { name: id, value: newVal } });
  };
  const onAddItem = (key: string) => {
    setItems(prev => {
      const currentCount = prev[key].items.length;
      const template = prev[key].editionData?.itemTemplate
        ?.filter(i => i)
        .map(i => ({
          ...i,
          name: `${i.name} ${currentCount / 2 + 1}`,
        }));
      return {
        ...prev,
        [key]: {
          ...prev[key],
          items: [...prev[key].items, ...(template || [])],
        },
      };
    });
  };
  const effectTriggeredRef = useRef(false);
  useEffect(() => {
    if (job && !effectTriggeredRef.current && job.id) {
      effectTriggeredRef.current = true;
      setValues(job[id] || {});
      // generate 5 applications
      if (job.matching_talents && job.matching_talents.hard_skills_applications) {
        setChecked(job.matching_talents?.hard_skills_applications);
      }
      // generate languages
      if (job.matching_talents && job.matching_talents.hard_skills_languages) {
        const countLanguages = Object.keys(job.matching_talents.hard_skills_languages).length / 2;
        if (countLanguages > 3) {
          for (let i = 3; i < countLanguages; i++) {
            onAddItem('hard_skills_languages');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return (
    <div className={classes.matchingSubCard} style={{ borderColor: color }}>
      <Typography
        variant="h3"
        color="secondary"
        className={classes.title}
        style={{ color, margin: '5px 0' }}>
        {title}
      </Typography>
      {Object.keys(itemsToUse).map(key => {
        const item = itemsToUse[key];
        return (
          <div key={item.title}>
            {!!item.title && <Typography className={classes.subtitle}>{item.title}</Typography>}
            {!!item.description && (
              <Typography className={classes.description}>
                {item.description}
                &nbsp; <span className={classes.descriptionOptionnel}>(Optionnel)</span>
              </Typography>
            )}
            <Grid container={true}>
              {item.items.map((formItem, formIndex) => (
                <Grid
                  key={formItem.name}
                  item={true}
                  xs={true}
                  className={
                    ['select', 'input'].includes(formItem.type!) ? classes.fieldContainer : ''
                  }
                  {...gridItemProps}>
                  {formItem.type !== 'radiomultiple' && (
                    <Typography className={classes.label}>
                      {formItem.name}{' '}
                      {formItem.required && <span className={classes.errorColor}>*</span>}
                    </Typography>
                  )}
                  {formItem.type === 'select' && (
                    <FormControl variant="outlined" fullWidth={true}>
                      <Select
                        labelWidth={0}
                        displayEmpty={true}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        renderValue={selected => {
                          if (!selected) {
                            return (
                              <span className={classes.selectPlaceholder}>
                                {formItem.placeholder}
                              </span>
                            );
                          }

                          return selected;
                        }}
                        error={
                          errors[id] &&
                          errors[id][key] &&
                          errors[id][key][formItem.name] &&
                          (!values[key] || !(values[key] as IObjectOfString)[formItem.name])
                        }
                        required={formItem.required}
                        className={classes.formControlSelect}
                        value={
                          (values[key] as IObjectOfString)
                            ? (values[key] as IObjectOfString)[formItem.name] ?? ''
                            : ''
                        }
                        onChange={e => handleOnChange(key, e.target.name, e.target.value as string)}
                        name={formItem.name}>
                        {formItem.allowDeselect && (
                          <MenuItem key="non" value="">
                            <span className={classes.selectPlaceholder}>
                              {formItem.placeholder}
                            </span>
                          </MenuItem>
                        )}
                        {(formItem.options as Array<string>)?.map(i => (
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors[id] &&
                        errors[id][key] &&
                        errors[id][key][formItem.name] &&
                        (!values[key] || !(values[key] as IObjectOfString)[formItem.name]) && (
                          <Typography className={classes.errorText}>Champ obligatoire</Typography>
                        )}
                    </FormControl>
                  )}
                  {formItem.type === 'input' && (
                    <FormControl variant="outlined" fullWidth={true} className={classes.formInput}>
                      <OutlinedInput
                        error={
                          errors[id] &&
                          errors[id][key] &&
                          errors[id][key][formItem.name] &&
                          (!values[key] || !(values[key] as IObjectOfString)[formItem.name])
                        }
                        required={formItem.required}
                        placeholder={formItem.placeholder}
                        value={
                          (values[key] as IObjectOfString)
                            ? (values[key] as IObjectOfString)[formItem.name] ?? ''
                            : ''
                        }
                        onChange={e => handleOnChange(key, e.target.name, e.target.value as string)}
                        name={formItem.name}
                        endAdornment={
                          <InputAdornment position="end">
                            {formItem.inputEndIcon ?? ''}
                          </InputAdornment>
                        }
                        {...formItem.inputProps}
                      />
                      {errors[id] &&
                        errors[id][key] &&
                        errors[id][key][formItem.name] &&
                        (!values[key] || !(values[key] as IObjectOfString)[formItem.name]) && (
                          <Typography className={classes.errorText}>Champ obligatoire</Typography>
                        )}
                    </FormControl>
                  )}
                  {formItem.type === 'radiomultiple' && (
                    <FormControl component="fieldset" className={classes.fieldset}>
                      <Typography className={clsx(classes.label, classes.feldsetPadding)}>
                        {formItem.name}{' '}
                        {formItem.required && <span className={classes.errorColor}>*</span>}
                      </Typography>
                      <RadioGroup
                        name={formItem.name}
                        className={classes.feldsetPadding}
                        value={
                          (values[key] as IObjectOfString)
                            ? (values[key] as IObjectOfString)[formItem.name] ?? ''
                            : ''
                        }>
                        {formItem?.options?.map(option => (
                          <FormControlLabel
                            key={option}
                            onClick={e => {
                              setChecked(prev => {
                                if (
                                  !prev.includes(`${formItem.name} - ${option}`) &&
                                  prev.length < 5
                                )
                                  return [...prev, `${formItem.name} - ${option}`];

                                const index = prev.indexOf(`${formItem.name} - ${option}`);
                                if (index > -1) {
                                  prev.splice(index, 1);
                                }
                                if (handleChange)
                                  handleChange({
                                    target: {
                                      name: id,
                                      value: { ...values, [key]: [...prev] },
                                    },
                                  });
                                return [...prev];
                              });
                            }}
                            className={classes.formControlLabel}
                            value={option}
                            control={
                              <Radio
                                checked={outilsChecked.includes(`${formItem.name} - ${option}`)}
                                color="primary"
                                size="small"
                                className={classes.radio}
                              />
                            }
                            label={
                              <div
                                onClick={() => {
                                  setChecked(prev => {
                                    if (
                                      !prev.includes(`${formItem.name} - ${option}`) &&
                                      prev.length < 5
                                    )
                                      return [...prev, `${formItem.name} - ${option}`];

                                    const index = prev.indexOf(`${formItem.name} - ${option}`);
                                    if (index > -1) {
                                      prev.splice(index, 1);
                                    }
                                    return [...prev];
                                  });
                                }}
                                className={classes.radioLabel}>
                                {option}
                              </div>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}

                  {formItem.type === 'radio' && (
                    <>
                      <FormControl
                        error={errors[id] && errors[id][key] && errors[id][key][formItem.name]}
                        component="fieldset"
                        className={classes.fieldsetRating}>
                        <RadioGroup
                          className={classes.paddingTop}
                          onChange={e =>
                            handleOnChange(key, e.target.name, e.target.value as string)
                          }
                          name={formItem.name}
                          value={
                            (values[key] as IObjectOfString)
                              ? (values[key] as IObjectOfString)[formItem.name] ?? ''
                              : ''
                          }>
                          {formItem?.options?.map((option, idx) => (
                            <div
                              key={idx}
                              className={clsx(
                                classes.ratingRow,
                                (values[key] as IObjectOfString) &&
                                  option === (values[key] as IObjectOfString)[formItem.name]
                                  ? classes.radioSelected
                                  : null
                              )}>
                              <FormControlLabel
                                className={classes.formControlLabel}
                                value={option}
                                control={
                                  <Radio color="primary" size="small" className={classes.radio} />
                                }
                                label={
                                  <div className={classes.radioLabel} key={idx}>
                                    <div className={classes.radioLabelFirst}>{option}</div>
                                    {formItem?.optionsIcons && formItem?.optionsIcons[idx] && (
                                      <div>
                                        {Array.from(Array(formItem?.optionsIcons[idx])).map(
                                          (i, o) => (
                                            <StarOutlineRounded key={o} color="primary" />
                                          )
                                        )}
                                        {Array.from(Array(4 - formItem?.optionsIcons[idx])).map(
                                          (i, o) => (
                                            <StarOutlineRounded key={o} color="disabled" />
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                }
                              />
                            </div>
                          ))}
                        </RadioGroup>
                        <div style={{ marginLeft: 10 }}>
                          {errors[id] &&
                            errors[id][key] &&
                            errors[id][key][formItem.name] &&
                            (!values[key] || !(values[key] as IObjectOfString)[formItem.name]) && (
                              <Typography className={classes.errorText}>
                                Champ obligatoire
                              </Typography>
                            )}
                        </div>
                      </FormControl>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
            {itemsToUse[key].canEdit && (
              <Grid container={true} justifyContent="center" alignItems="center">
                <Grid item={true}>
                  <Button
                    color="inherit"
                    className={classes.langBtn}
                    onClick={() => onAddItem(key)}>
                    Ajouter une langue
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MatchingSection;
