// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import SimpleBanner from '../../components/SimpleBanner';

const Page36 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={36}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          DÉCOUVRIR VOTRE PERSONNALITÉ <br />
          EN GROUPE
        </Typography>
      </div>
      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: '50px', marginBottom: '50px' }}
      />
      <Paragrapth align="justify">
        Chaque personne est amenée à un moment donné de sa carrière à s’intégrer dans une équipe. Au
        sein de cette équipe, nous assumons un certain nombre de rôles. Chaque personne a une
        propension naturelle pour certains rôles et une aversion naturelle pour d’autres. Connaître
        le rôle que nous apprécions d’occuper au sein d’une équipe est très important. En effet,
        cela nous permet de savoir si nous sommes à la bonne place et de mieux comprendre les
        ressentis négatifs ou positifs que nous éprouvons au sein de certains groupes.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        En effet, il n’est pas rare de ne pas pouvoir choisir le rôle que l’on va occuper au sein
        d’une équipe. Tout simplement parce que la place est déjà prise par quelqu’un d’autre.
        Ainsi, le rôle de leader(euse) que l’on peut souhaiter occuper peut déjà être pris par une
        ou deux personnes qui se livrent déjà une concurrence féroce. La solution dans ce cas est
        soit de rentrer dans cette compétition aux conséquences souvent très dommageables ou, le cas
        échéant, de trouver un autre groupe où cette position est vacante.
      </Paragrapth>
      <br />
      <br />
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: 80 }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre51.png`} alt="logo" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}>
              LEADER LE JOUR, SUIVEUR LA NUIT
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook2}/illustration_page_51.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Thomas est un vrai leader. <br />
          Dans le lycée où il étudie, il fait partie des personnes qui sont les plus suivies, que
          cela soit sur sa façon de s’habiller, de parler, de se comporter en dehors ou dans la
          classe. Thomas suscite une réelle admiration chez ses ami(e)s de sa classe.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Le week-end, quand il est en dehors du lycée, Thomas fréquente un autre groupe d’amis.
          Celui (Celle) qui le connaît la semaine serait surpris(e) de voir comment il se comporte
          dans ce groupe-ci. Thomas prend rarement des décisions. Il suit souvent l’avis de
          Christophe et Olivier, les deux vrais leaders du groupe.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Il les imite souvent dans leur façon d’être, de parler et de se comporter. Dans ce groupe,
          ce sont Christophe et Olivier les vraies stars, ceux qu’on écoute et qui dirigent le
          groupe, les vrais leaders. Thomas est leader dans un groupe et totalement suiveur dans un
          autre. La question est la suivante : quel rôle préfère-t-il ?
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Quelle place aimerait-il occuper vraiment ?
        </Typography>
      </div>
      <br />
      <br />
      <div style={{ marginBottom: '20px' }}>
        <div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            L’outil suivant va vous permettre de mieux identifier votre personnalité dans un groupe.
            Il vous aidera à identifier la place que vous appréciez d’occuper, celle dans laquelle
            vous vous sentez à l’aise et performant(e).
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Décrire cette position préférée pourra vous être utile lors d’un entretien de
            recrutement afin de savoir si vous avez votre place dans l’équipe dans laquelle on
            souhaite vous intégrer.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Une personnalité de leader(euse) ne saurait en effet bien s’intégrer dans une équipe où
            il y a déjà un(e) ou deux leaders(euses).
          </Typography>
        </div>
      </div>
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '45%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-20px',
      float: 'left',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);
export default Page36;
