import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      overflow: 'hidden',
    },
    matchTitle: {
      color: '#151528',
      fontSize: 20,
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      marginLeft: 20,
    },
    title: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: 16,
      color: '#000A8C',
    },
    cardContainer: {
      padding: 40,
      borderRadius: 5,
      background: 'white',
      borderWidth: 2,
    },
    trait: {
      display: 'block',
      width: 1,
      height: 30,
      background: '#000A8C',
    }
  })
);
