/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ContainerJob, InputSearch, MarginContainer } from '@orientaction/commons';
import { CardMatching, JobLayout, TableAnnonce } from '@orientaction/components';
import { useStyles } from './style';
import { ReactComponent as ArrowLeft } from '../../../Asset/svg/arrow_left_alt-2.svg';
import { ReactComponent as M1 } from '../../../Asset/svg/matching1.svg';
import { ReactComponent as M2 } from '../../../Asset/svg/matching2.svg';
import { ReactComponent as M3 } from '../../../Asset/svg/matching3.svg';
import { ReactComponent as M4 } from '../../../Asset/svg/matching4.svg';
import { ReactComponent as M5 } from '../../../Asset/svg/matching5.svg';
import { useHistory } from 'react-router-dom';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import MatchingSensDuTravail from './MatchingSensDuTravail';
import MatchingBesoinMotivation from './MatchingBesoinMotivation';
import MatchingPersonality from './MatchingPersonality';
import MatchingTalent from './MatchingTalent';

const DetailCandidat = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <JobLayout isNew3={true}>
      <div className={classes.header}>
        <div className={classes.btn} onClick={() => history.push('/admin/emploi/candidats')}>
          <ArrowLeft />
          <div style={{ marginLeft: 15 }}>Retour</div>
        </div>
        <Grid container={true} justifyContent="space-between" alignItems="center">
          <Grid item={true} lg={4}>
            <div className={classes.containerProfile}>
              <img className={classes.imgProfile} src="/images/job/board-presenter2.png" alt="" />
              <Typography variant="h6" className={classes.nameProfile}>
                Aymeric Laurent
                <div style={{ marginTop: 10 }}>aymeric.laurent@gmail.com</div>
                <div>+33 6 01 02 03 04</div>
              </Typography>
            </div>
          </Grid>
          <Grid
            container={true}
            item={true}
            lg={7}
            style={{ border: '1px dashed #5E65A3', borderRadius: 5 }}>
            <Grid
              item={true}
              lg={5}
              style={{ borderBottom: '1px dashed #5E65A3', borderRight: '1px dashed #5E65A3' }}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Distance domicile/emploi (géo)
                  <div style={{ marginTop: 2 }}>4 Km</div>
                </Typography>
              </div>
            </Grid>
            <Grid
              item={true}
              lg={3}
              style={{ borderBottom: '1px dashed #5E65A3', borderRight: '1px dashed #5E65A3' }}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Niveau de diplôme
                  <div style={{ marginTop: 2 }}>Master</div>
                </Typography>
              </div>
            </Grid>
            <Grid item={true} lg={4} style={{ borderBottom: '1px dashed #5E65A3' }}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Nombre d’années d’expériences
                  <div style={{ marginTop: 2 }}>12</div>
                </Typography>
              </div>
            </Grid>
            <Grid item={true} lg={5} style={{ borderRight: '1px dashed #5E65A3' }}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Situation actuelle
                  <div style={{ marginTop: 2 }}>Libre</div>
                </Typography>
              </div>
            </Grid>
            <Grid item={true} lg={3} style={{ borderRight: '1px dashed #5E65A3' }}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Mois de préavis
                  <div style={{ marginTop: 2 }}>3</div>
                </Typography>
              </div>
            </Grid>
            <Grid item={true} lg={4}>
              <div className={classes.cellule}>
                <Typography variant="h6" className={classes.cellulename}>
                  Ville
                  <div style={{ marginTop: 2 }}>Antananarivo</div>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.body}>
        <div style={{ position: 'relative', top: '-60px' }}>
          <div className={classes.containerSection}>
            <CardMatching
              value={90}
              title="Chef de Projet Marketing Opérationnel Marché Restauration Collective H/F"
            />
          </div>
          <div className={classes.containerSection}>
            <MatchingSensDuTravail />
          </div>
          <div className={classes.containerSection}>
            <MatchingBesoinMotivation />
          </div>
          <div className={classes.containerSection}>
            <MatchingPersonality />
          </div>
          <div className={classes.containerSection}>
            <MatchingTalent />
          </div>
        </div>
      </div>
    </JobLayout>
  );
};

export default DetailCandidat;
