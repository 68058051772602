/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
// eslint-disable-next-line simple-import-sort/imports
import { IconButton } from '@material-ui/core';
import { useStyles } from './style';
import { ReactComponent as Border } from '../../../Asset/svg/border.svg';
import { ReactComponent as Deletec } from '../../../Asset/svg/deletered.svg';
import { ReactComponent as View } from '../../../Asset/svg/view.svg';
import { ReactComponent as Check } from '../../../Asset/svg/check-3.svg';
import { ReactComponent as Warning } from '../../../Asset/svg/warning.svg';
import { ReactComponent as Group } from '../../../Asset/svg/group.svg';
import { useHistory } from 'react-router-dom';

const ItemAnnonce = (props: any) => {
  const classes = useStyles();
  const { dataColumns, annonce, onDelete } = props;
  const history = useHistory();

  return (
    <div className={classes.itemParent}>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[0]} - 5px` }}>
        {annonce?.title}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[1]} - 5px` }}>
        {annonce?.date}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[2]} - 5px` }}>
        {annonce?.adress}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[3]} - 5px` }}>
        {annonce?.candidats ? `${annonce?.candidats} Cand.` : ''}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[4]} - 5px` }}>
        <div className={classes.status}>
          {annonce?.status === 'En brouillon' ? <Warning /> : <Check />}
          <span
            style={{
              marginLeft: 7,
              color: annonce?.status === 'En brouillon' ? '#E63C3C' : '#5CCA28',
            }}>
            {annonce?.status}
          </span>
        </div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[5]} - 5px` }}>
        <div style={{ width: '100%' }}>
          {annonce?.candidats ? (
            <IconButton
              aria-label="invite"
              onClick={() => history.push(`/admin/emploi/annonce/${annonce?.id}`)}>
              <Group className={classes.iconM} />
            </IconButton>
          ): ''}
          <IconButton aria-label="view" onClick={() => console.log('page annonce')}>
            <View className={classes.iconM} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => history.push(`/admin/emploi/annonces/${annonce?.id}`)}>
            <Border className={classes.iconM} />
          </IconButton>
          <IconButton aria-label="delete">
            <Deletec
              className={classes.iconM}
              onClick={() => {
                if (onDelete) onDelete(annonce);
              }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default ItemAnnonce;
