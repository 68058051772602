import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      borderBouttom: 'none',
      border: '1px solid #BCBCBC',
      paddingTop: '0.75vh',
      paddingLeft: '1.5vh',
      backgroundColor: 'white',
      borderRadius: '4px',
      width: '90%',
      '&.MuiInput-underline': {
        borderBouttom: 'none',
      },
    },
    iconButton: {
      color: 'rgba(14, 18, 71, 1)',
      position: 'relative',
      left: 0,
      fontSize: 35,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btnPrimary: {
      padding: '0 2.5vh',
      minHeight: 30,
      textTransform: 'none',
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
    },
    btnEdit: {
      padding: '0 2.5vh',
      minHeight: 30,
      textTransform: 'none',
      color: '#0E1247',
      margin: '0 1vh',
      border: '1px solid #BCBCBC',
    },
    bodycenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    cardContent: {
      marginRight: '-3%',
      [theme.breakpoints.down(600)]: {
        marginRight: '0%',
      },
    },
    inlineContent: {
      width: '30%',
      marginRight: '3%',
      marginBottom: '1.25%',
      float: 'left',
      [theme.breakpoints.down(1000)]: {
        width: '48%',
        margin: '1vh 0.5vh',
      },
      [theme.breakpoints.down(600)]: {
        float: 'none',
        width: '100%',
        margin: '1vh 0',
      },
    },
    both: {
      clear: 'both',
    },
    btnPrimarySeek: {
      background: '#000A8C',
      textTransform: 'none',
    },
    inputSearch: {
      height: 41,
      marginRight: 20,
      border: '1px solid #000A8C',
      background: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    separator: {
      margin: '20px 0px 30px',
      width: '100%',
      position: 'relative',
      height: '2px',
      background: '#E4E4F2',
      display: 'block',
    },
    loader: {
      width: 35,
      height: 35,
      display: 'block',
      margin: 'auto',
    },
    textNoResult: {
      fontSize: 20,
      fontWeight: 500,
      textAlign: 'center',
    },
    separator2: {
      width: '100%',
      height: 1,
      background: '#E4E4F2',
      margin: '25px 0px 25px',
    },
    contentDrawer: {
      position: 'relative',
      width: '50%',
      margin: '40px auto',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },
    },
    titleTests: {
      fontSize: 30,
      fontFamily: 'Poppins',
      textTransform: 'none',
      lineHeight: '36px',
    },
    drawerContent: {
      borderTop: '2px solid #23296F',
      borderRadius: 16,
      overflowX: 'hidden',
      minHeight: 300,
      maxHeight: '80vh',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    rootStyle: {
      background: '#000a8c6b',
    },
    iconBtnImage: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      top: '1.5%',
      right: '1%',
      zIndex: 2,
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#E4E4F2',
        top: '-6px',
      },
      '& div input': {
        fontSize: 14,
      },
      '& .MuiFormHelperText-contained': {
        color: 'red',
      },
    },
    label: {
      fontSize: 14,
      marginBottom: 10,
      fontWeight: 500,
      fontFamily: 'Poppins',
    },
    containerField: {
      margin: '25px 0px',
    },
    select: {
      width: '100%',
      '& .MuiSelect-iconOutlined': {
        color: 'black',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'threedface !important',
      },
    },
    btnCancel: {
      background: 'transparent',
      border: '1px solid #E63C3C',
      color: '#E63C3C',
      textTransform: 'none',
      width: '100%',
      boxShadow: 'none',
    },
    btnConfirm: {
      background: '#000A8C',
      textTransform: 'none',
      width: '100%',
      boxShadow: 'none',
    },
    formInputRed: {
      width: '100%',
      '& div fieldset': {
        borderColor: 'red',
        top: '-6px',
      },
      '& div input': {
        fontSize: 14,
      },
    },
    modalText: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
  })
);
