/* eslint-disable no-shadow */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import { PermissionSelectGame, SimpleModal } from '@orientaction/components';
import { StatusItemGame } from '@orientaction/utils';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Border } from '../../../../Asset/svg/border.svg';
import { ReactComponent as Check } from '../../../../Asset/svg/check.svg';
import { ReactComponent as Delete } from '../../../../Asset/svg/delete.svg';
import { ReactComponent as Setting } from '../../../../Asset/svg/settings.svg';
import { apiServiceNative } from '../../../../utils/fetchNative';
import ShareIcon from '@material-ui/icons/Share';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClearIcon from '@material-ui/icons/Clear';
import style from './style';

interface IItemGame {
  id: string;
  title: string;
  pageNumber: number;
  status: StatusItemGame;
  callBack?: (isOk: boolean) => void;
  options?: any;
  permission?: any;
  isEbook?: boolean;
}

const ItemGame: FC<IItemGame> = ({
  id,
  title,
  pageNumber,
  status,
  callBack,
  options,
  permission,
  isEbook,
}) => {
  const classes = style();
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showMessage, setShowmessage] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openModalNotShare, setOpenModalNotShare] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const baseUrl = `${window.location.origin}`;
  const [permissionForSharing, setPermissionForSharing] = useState<any>(permission);

  const dispatch = useDispatch();
  const deleteGame = async () => {
    const deletedGame = await apiServiceNative.delete(`/api/remove-game/${id}`);

    if (callBack && deletedGame.ok) {
      callBack(true);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowmessage(false);
    }, 2000);
  }, [showMessage]);

  const openModalShare = () => {
    if (StatusItemGame.draft === status || permissionForSharing.isAttach) {
      if (StatusItemGame.draft === status) {
        setMessageError("Impossible de partager ce jeu car il n'est pas publié");
      }
      if (permissionForSharing.isAttach) {
        setMessageError(
          "Impossible de partager ce jeu car il n'est pas autorisé aux bénéficiaires non-rattachés"
        );
      }
      setOpenModalNotShare(true);
      setOpenShareModal(false);
    } else {
      setOpenShareModal(true);
      setOpenModalNotShare(false);
    }
  };

  console.log({ permission });

  return (
    <div className={classes.container}>
      <Grid container={true}>
        <Grid container={true} item={true} sm={5}>
          <Grid item={true} sm={6} className={classes.gridCenterVerticaly}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item={true} sm={4} className={classes.btnBorder}>
            <Button
              size="small"
              color="primary"
              disableRipple={true}
              className={classes.btn}
              style={{ background: `${StatusItemGame.draft === status ? '#BFC0D5' : '#4C8612'}` }}
              endIcon={StatusItemGame.draft === status ? <Setting /> : <Check />}>
              {StatusItemGame.draft === status ? 'Brouillon' : 'Publié'}
            </Button>
          </Grid>
          <Grid item={true} sm="auto" className={classes.gridCenterVerticaly}>
            <Typography variant="h4" className={classes.pageNumber}>
              {pageNumber} pages{' '}
            </Typography>
          </Grid>
        </Grid>
        <Grid item={true} sm={7} className={classes.containerRight}>
          <div className={classes.containerSelect}>
            <PermissionSelectGame
              options={options}
              idGame={id}
              permission={permission}
              handleShow={(show: boolean) => setShowmessage(show)}
              handlePermission={(prm: any) => setPermissionForSharing(prm)}
            />
            {showMessage && (
              <Typography variant="h5" className={classes.text}>
                Choix sauvegardés
              </Typography>
            )}
          </div>
          <div style={{ paddingLeft: 20 }}>
            <IconButton aria-label="delete" onClick={openModalShare}>
              <ShareIcon style={{ fontSize: 14 }} color="primary" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                dispatch(gameBookTemplateActions.resetValues());
                sessionStorage.clear();
                sessionStorage.setItem('id', id);
                history.push(`/admin/game/edit-page/${id}`);
              }}>
              <Border />
            </IconButton>

            <IconButton aria-label="delete" onClick={() => setOpenModal(true)}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Êtes vous sur de vouloir supprimer ce jeu ?"
        textClick="Oui"
        textClose="Non"
        handleClick={deleteGame}
      />
      <SimpleModal
        open={openModalNotShare}
        handleClose={() => setOpenModalNotShare(false)}
        title="Information"
        message={messageError}
        textClose="Ok"
      />
      <Dialog
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          style={{ position: 'absolute', top: '5px', right: '5px' }}
          onClick={() => setOpenShareModal(false)}>
          <ClearIcon fontSize="small" />
        </IconButton>
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px' }}>
          Partager
        </DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: '20px', paddingBottom: '10px', width: 550 }}>
            <FormControl
              className={classes.styleInput}
              variant="outlined"
              style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-adornment-password"
                value={`${baseUrl}/game/${id}/${title?.replace(/\s+/g, '-')}`}
                readOnly={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${baseUrl}/game/${id}/${title?.replace(/\s+/g, '-')}`
                        );
                      }}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ItemGame;
