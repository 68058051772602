/* eslint-disable no-restricted-syntax */
import { useAlert } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiServiceNative } from '../../../../utils/fetchNative';
import {
  matching_besoins_et_motivation,
  matching_equilibre_de_vie,
  matching_personnalite,
  matching_sens_du_travail,
  matching_talents,
} from './matching.constant';
import { IMachingData } from './MatchSection/type';
import { IJobMatching, MatchingKey, MatchingValues } from './type';

interface IPopin {
  open: boolean;
  title: string;
  content: string;
  type: string;
  data?: any;
}

const popinInitialState = {
  open: false,
  title: '',
  content: '',
  type: 'default',
};
const initialValues = {
  matching_besoins_et_motivation: {},
  matching_equilibre_de_vie: {},
  matching_personnalite: {},
  matching_sens_du_travail: {},
  matching_talents: {},
};

const useJobForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [popin, setPopin] = useState<IPopin>(popinInitialState);
  const [job, setJob] = useState<IJobMatching>({
    title: '',
    intro: '',
    consultant: undefined,
    year_experiences: [],
    disponibilities: [],
    matching_sens_du_travail: undefined,
    matching_talents: undefined,
    matching_personnalite: undefined,
    matching_equilibre_de_vie: undefined,
    matching_besoins_et_motivation: undefined,
  });
  const [errors, setErrors] = useState<MatchingValues>(initialValues);
  const history = useHistory();
  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');

  const alert = useAlert();

  const handlePopinConfirmDelete = async () => {
    setLoading(true);
    try {
      await apiServiceNative.delete(`/api/jobs/${popin.data?.id}`);
      alert.setOpenAlert(true, 'Votre annonce est bien supprimée', 'success');
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setPopin(popinInitialState);
      setLoading(false);
    }
  };
  const handlePopinConfirmToDraft = async () => {
    setLoading(true);
    try {
      await handleSaveDraft(popin.data?.id, { ...popin.data, publishedAt: null });
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      handlePopinClose();
      setLoading(false);
    }
  };
  const handlePopinConfirmToPublish = async (e: any) => {
    setLoading(true);
    try {
      setPopin(popinInitialState);
      history.push(`/admin/emploi/preview-annonce/${popin.data?.id}`, {
        state: { job: popin.data },
      });
      // OR await publishAfterPreview(e, popin.data?.id);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setPopin(popinInitialState);
      setLoading(false);
    }
  };

  const handlePopinClose = () => {
    setPopin(() => ({ title: '', content: '', open: false, type: 'default' }));
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setJob((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any, id: string) => {
    e?.preventDefault();
    e?.stopPropagation();
    const dataSource: { [key: string]: { [key: string]: IMachingData } } = {
      matching_besoins_et_motivation,
      matching_equilibre_de_vie,
      matching_personnalite,
      matching_sens_du_travail,
      matching_talents,
    };
    let hasError = false;
    // validation for all required field
    const validation: any = {};
    const requiredKeys = [
      'title',
      'intro',
      'addressCity',
      'degree_levels',
      'disponibilities',
      'year_experiences',
    ];
    for (const key of requiredKeys) {
      if (!job || ((!(job as any)[key] || (job as any)[key].length === 0) && !hasError)) {
        hasError = true;
      }
      validation[key as MatchingKey] = !job || !(job as any)[key] || (job as any)[key].length === 0;
    }
    for (const key in dataSource) {
      if (job) {
        if (!(job as any)[key]) {
          (job as any)[key] = {};
        }
        validation[key as MatchingKey] = {};
        for (const k in dataSource[key as MatchingKey]) {
          if (dataSource[key as MatchingKey][k]) {
            (validation[key as MatchingKey] || {})[k] = {};
            // eslint-disable-next-line no-loop-func
            dataSource[key as MatchingKey][k].items.forEach(i => {
              if (
                i.required &&
                !hasError &&
                (!(job[key as MatchingKey] || {})[k] || !(job[key as MatchingKey] || {})[k][i.name])
              ) {
                hasError = true;
              }
              if (!(validation[key as MatchingKey] || {})[k]) {
                (validation[key as MatchingKey] || {})[k] = {};
              }
              (validation[key as MatchingKey] || {})[k][i.name] =
                i.required &&
                (!(job[key as MatchingKey] || {})[k] ||
                  !(job[key as MatchingKey] || {})[k][i.name]);
            });
          }
        }
      }
    }
    setErrors(validation);
    if (hasError) {
      alert.setOpenAlert(
        true,
        'Veuillez remplir toutes les champs obligatoire pour pouvoir publier votre annonce',
        'error'
      );
      return;
    }
    if (job?.publishedAt) {
      return confirmPublish(job);
    }
    const data = await handleSaveDraft(id, job);
    history.push(`/admin/emploi/preview-annonce/${data.id}`, { state: { job } });
  };
  const publishAfterPreview = async (id: string, data: IJobMatching) => {
    try {
      let response;
      if (id === 'new') {
        response = await apiServiceNative.post(`/api/jobs`, { data });
        // here redirect to edit
        history.replace(`/admin/emploi/annonces/${response.data?.id}`);
      } else {
        response = await apiServiceNative.put(`/api/jobs/${id}`, { data });
      }
      setJob({ ...response.data.attributes, id: response.data.id });
      alert.setOpenAlert(true, 'Votre annonce est maintenant publiée', 'success');
      history.replace(`/admin/emploi/annonces/${response.data?.id}`);
    } catch (e) {
      alert.openSimpleAlert();
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleSaveDraft = async (id: string, data: IJobMatching, withAlert?: boolean) => {
    try {
      setLoading(true);
      if (data.publishedAt) {
        // this is already published, lets confirm
        return confirmToDraft(data);
      }
      let response;
      data.consultant = currentUser.id;
      data.publishedAt = null;
      if (id === 'new') {
        response = await apiServiceNative.post(`/api/jobs`, { data });
        // here redirect to edit
        history.replace(`/admin/emploi/annonces/${response.data?.id}`);
      } else {
        response = await apiServiceNative.put(`/api/jobs/${id}`, { data });
        setJob({ ...response.data.attributes, id: response.data.id });
      }
      setErrors(initialValues);
      if (withAlert) {
        alert.setOpenAlert(true, 'Votre annonce est enregistré en tant que brouillon', 'success');
      }
      setLoading(false);
      return response.data
    } catch (e) {
      alert.openSimpleAlert();
      console.error(e);
      setLoading(false);
    }
  };
  const loadData = async (id: string) => {
    try {
      setLoading(true);
      const {
        data: { attributes },
      } = await apiServiceNative.get(`/api/jobs/${id}`);
      setJob({ ...attributes, id });
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  const confirmDelete = (data: IJobMatching) => {
    setPopin(() => ({
      title: 'Confirmation',
      content: 'Etes-vous sûr de vouloir supprimer cette annonce?',
      open: true,
      data,
      type: 'delete-job',
    }));
  };
  const confirmToDraft = (data: IJobMatching) => {
    setPopin(() => ({
      title: 'Confirmation',
      content:
        'Attention, votre annonce ne sera plus publiée et le recrutement sera interrompue. Êtes-vous sûr de vouloir faire ça ?',
      open: true,
      data,
      type: 'confirm-draft',
    }));
  };
  const confirmPublish = (data: IJobMatching) => {
    setPopin(() => ({
      title: 'Confirmation',
      content:
        'Attention, vous êtes sur le point de modifier votre annonce qui est déjà publiée. Êtes-vous sûr de vouloir continuer ?',
      open: true,
      data,
      type: 'confirm-publish',
    }));
  };
  const getPopinConfirmFunction = (type: string) => {
    let func;
    switch (type) {
      case 'delete-job':
        func = handlePopinConfirmDelete;
        break;

      case 'confirm-draft':
        func = handlePopinConfirmToDraft;
        break;
      case 'confirm-publish':
        func = handlePopinConfirmToPublish;
        break;

      default:
        func = handlePopinConfirmDelete;
        break;
    }
    return func;
  };

  return {
    errors,
    handleChange,
    handleSubmit,
    handleSaveDraft,
    loading,
    popin,
    setJob,
    confirmDelete,
    handlePopinClose,
    handlePopinConfirm: getPopinConfirmFunction(popin?.type),
    job,
    loadData,
    publishAfterPreview,
  };
};

export default useJobForm;
