/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { ReactComponent as Sorticon } from '../../Asset/svg/sort.svg';
import ItemAnnonce from './ItemAnnonce';
import { useMemo, useState } from 'react';
import { cloneDeep, findIndex, orderBy, sortBy } from 'lodash';
import Pagination from './Pagination';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import OaPopin from '@orientaction/commons/Popin';
import useJobForm from '../Form/CreateEditAdmin/Job/useJobForm';

const enum StatusOrderEnum {
  asc = 'asc',
  desc = 'desc',
}

const TableAnnonce = (props: any) => {
  const { confirmDelete, popin, handlePopinConfirm, handlePopinClose } = useJobForm();
  const classes = useStyles();
  const headers = [
    {
      name: 'Titres des annonces',
      xs: '27%',
      sort: false,
      field: 'title',
    },
    {
      name: 'Publiée',
      xs: '12%',
      sort: true,
      field: 'date',
    },
    {
      name: 'Ville',
      xs: '25%',
      sort: false,
      field: 'lastname',
    },
    {
      name: 'Candidats',
      xs: '10%',
      sort: false,
    },
    {
      name: 'Status',
      xs: '10%',
      sort: true,
      field: 'status',
    },
    {
      name: 'Action',
      xs: '15%',
      sort: false,
    },
  ];
  const { annonces, loading, pagination, handleChangePagination, onReload } = props;
  const [elemToSort, setElemToSort] = useState<Array<any>>([]);
  const [headersD, setHeadersD] = useState<any>(headers);
  const annonceSorted = useMemo(() => {
    return orderBy(
      annonces,
      elemToSort.map(el => el.elem),
      elemToSort.map(el => el.type)
    );
  }, [annonces, elemToSort]);

  const handleSort = (elem: string, index: number) => {
    const found = findIndex(elemToSort, function (o) {
      return o.elem === elem;
    });
    const dataCloned = cloneDeep(elemToSort);
    const headersCloned = cloneDeep(
      headersD.map((elem: any, ind: number) => {
        if (index !== ind) {
          elem.type = undefined;
        }
        return elem;
      })
    );
    dataCloned[0] = {
      elem,
      type:
        StatusOrderEnum.asc === headersCloned[index]?.type
          ? StatusOrderEnum.desc
          : StatusOrderEnum.asc,
    };
    headersCloned[index].type =
      StatusOrderEnum.asc === headersCloned[index]?.type
        ? StatusOrderEnum.desc
        : StatusOrderEnum.asc;
    setElemToSort(dataCloned);
    setHeadersD(headersCloned);
  };

  return (
    <div>
      <div className={classes.gridParent}>
        {headersD.map((elem: any, index: number) => (
          <div style={{ width: elem.xs }}>
            <div className={classes.titleContainer}>
              <p className={classes.title}>{elem.name}</p>
              {elem.sort && (
                <div onClick={() => elem.sort && handleSort(elem.field, index)}>
                  {(elem.type === undefined || elem.type === null) && (
                    <Sorticon className={classes.icon} />
                  )}
                  {elem.type === StatusOrderEnum.asc && (
                    <ArrowDownwardIcon className={classes.iconM} />
                  )}
                  {elem.type === StatusOrderEnum.desc && (
                    <ArrowUpwardIcon className={classes.iconM} />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.bodyList}>
        {loading && <CircularProgress className={classes.loader} />}
        {!loading &&
          annonceSorted &&
          annonceSorted.map((annonce: any) => (
            <ItemAnnonce
              annonce={annonce}
              dataColumns={headers.map(elem => elem.xs)}
              onDelete={confirmDelete}
            />
          ))}
      </div>
      <div style={{ marginTop: 20 }}>
        <Pagination
          count={pagination.total}
          page={pagination.page - 1}
          rowsPerPage={10}
          onChangePage={handleChangePagination}
        />
      </div>
      <OaPopin
        open={popin.open}
        title={popin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={async () => {
          await handlePopinClose();
          if (onReload) onReload();
        }}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {popin.content}
          </Typography>
        </div>
      </OaPopin>
    </div>
  );
};
export default TableAnnonce;
