/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Loader } from '@googlemaps/js-api-loader';
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useStyles } from './style';
import GooglePlacesAutocompleteProps, {
  AutocompletionRequest,
  GooglePlacesAutocompleteHandle,
  IAddress,
  PredictionOption,
} from './types';
import { autocompletionRequestBuilder } from './utils';

export const getPostcodeByLatLng = async (lat: number, lng: number, apiKey: string) => {
  if (!lat || !lng) return null;

  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
  );
  if (!res.ok) {
    return null;
  }
  const data = await res.json();
  const haspostalcodeItem = data?.results?.find(({ address_components }: any) => {
    return address_components.find(({ types }: any) => types.includes('postal_code'));
  });
  if (!haspostalcodeItem) return null;
  return haspostalcodeItem.address_components.find(({ types }: any) =>
    types.includes('postal_code')
  ).short_name;
};

const GooglePlacesAutocomplete: React.ForwardRefRenderFunction<
  GooglePlacesAutocompleteHandle,
  GooglePlacesAutocompleteProps
> = (
  {
    onDone,
    inputValue,
    setInputValue,
    value,
    setValue,
    apiKey = '',
    apiOptions = {
      language: 'FR',
      region: 'FR',
    },
    autocompletionRequest = {
      types: ['(cities)'],
      componentRestrictions: { country: 'fr' },
    },
    debounce = 250,
    minLengthAutocomplete = 0,
    onLoadFailed = console.error,
    withSessionToken = false,
    label,
    inputProps = {},
    ...autocompleteProps
  }: GooglePlacesAutocompleteProps,
  ref
): JSX.Element => {
  const classes = useStyles();
  const [autoCompleteService, setAutoCompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>(
    undefined
  );
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);
  const [options, setOptions] = useState<PredictionOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [internalSelectedOption, setInternalSelectedOption] = useState<PredictionOption | null>(
    null
  );
  const fetchSuggestions = useDebouncedCallback(async (val: string) => {
    if (!autoCompleteService || val.length < minLengthAutocomplete) return setOptions([]);
    setLoading(true);

    const autocompletionReq: AutocompletionRequest = {
      ...autocompletionRequest,
    };
    try {
      const suggestions = await autoCompleteService.getPlacePredictions(
        autocompletionRequestBuilder(autocompletionReq, val, withSessionToken && sessionToken)
      );
      setOptions(suggestions.predictions || []);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  }, debounce);

  const initializeService = (): void => {
    if (!window.google)
      throw new Error('[mui-google-places-autocomplete]: Google script not loaded');
    if (!window.google.maps)
      throw new Error('[mui-google-places-autocomplete]: Google maps script not loaded');
    if (!window.google.maps.places)
      throw new Error('[mui-google-places-autocomplete]: Google maps places script not loaded');

    setAutoCompleteService(new window.google.maps.places.AutocompleteService());
    setPlacesService(new google.maps.places.PlacesService(document.createElement('input')));
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  const onInputValueChange = (_: any, newInputValue: string, reason: string): void => {
    if (reason === 'input') {
      setInputValue(newInputValue);
    }
  };

  const renderInputField = (props: AutocompleteRenderInputParams): JSX.Element => {
    return (
      <TextField
        {...props}
        {...inputProps}
        fullWidth={true}
        variant="outlined"
        className={classes.formInput}
        value=""
      />
    );
  };
  const getAddressDetails = (place: any): IAddress => {
    const addressComponent: Array<any> | undefined = place.address_components;
    const streetNumber = addressComponent?.find(g =>
      g.types.find((t: string) => t === 'street_number')
    )?.long_name;
    const streetName = addressComponent?.find(g =>
      g.types.find((t: string) => t === 'route')
    )?.long_name;
    const cityName = addressComponent?.find(
      g =>
        g.types.find((t: string) => t === 'locality') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const stateName = addressComponent?.find(
      g =>
        g.types.find((t: string) => t === 'administrative_area_level_1') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const region = addressComponent?.find(
      g =>
        g.types.find((t: string) => t === 'administrative_area_level_2') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const countryName = addressComponent?.find(
      g =>
        g.types.find((t: string) => t === 'country') &&
        g.types.find((t: string) => t === 'political')
    )?.long_name;
    const countryCode = addressComponent?.find(
      g =>
        g.types.find((t: string) => t === 'country') &&
        g.types.find((t: string) => t === 'political')
    )?.short_name;
    const zip = addressComponent?.find(g =>
      g.types.find((t: string) => t === 'postal_code')
    )?.long_name;

    const latLng = place?.geometry?.location?.toJSON();

    const address: IAddress = {};
    if (streetNumber && streetNumber) {
      address.addressLine = `${streetNumber} ${streetName}`;
    } else {
      address.addressLine = place.name || place.formatted_address;
    }
    address.addressLine = place.name || place.formatted_address;
    if (cityName) address.addressCity = cityName;
    // if (region) address.region = region;
    if (countryName) address.addressCountry = countryName;
    // if (countryCode) address.countryCode = countryCode;
    if (zip) address.addressZipcode = zip;
    if (latLng) {
      const { lat, lng } = latLng;
      address.addressLat = lat;
      address.addressLng = lng;
    }

    return address;
  };

  const onSelectedOptionChange = (_: any, placeObject: any | undefined) => {
    if (!placeObject) {
      // clear
      setDisplayValue('');
      setInputValue('');
      if (setValue) setValue(null);
      return;
    }
    const placeId = placeObject?.place_id;
    if (placeObject) setOptions([placeObject, ...options]);
    if (setValue) setValue(placeObject!);
    else setInternalSelectedOption(placeObject!);

    if (placesService && placeId) {
      placesService.getDetails(
        {
          placeId,
        },
        async (place, status) => {
          if (place && status === 'OK') {
            const address: IAddress = getAddressDetails({ ...place, name: placeObject.label });
            if (!address.addressZipcode && address.addressLat && address.addressLng) {
              try {
                const postalCode = await getPostcodeByLatLng(
                  address.addressLat,
                  address.addressLng,
                  apiKey
                );
                address.addressZipcode = postalCode;
              } catch (e) {
                console.log(e);
              }
            }
            setInputValue(`${address.addressCity} (${address.addressZipcode?.substring(0, 2)})`);
            setDisplayValue(`${address.addressCity} (${address.addressZipcode?.substring(0, 2)})`);
            if (onDone) onDone(address);
          } else {
            console.error('autoCompleteService has error when retrieving place details');
          }
        }
      );
    } else {
      console.error('google.maps.places.autoCompleteService is not loaded');
    }
  };

  const renderAutocompleteOption = (option: any, props: any): JSX.Element => {
    const parts = [{ text: option.structured_formatting.main_text }];
    return (
      <div className={props.selected ? 'selected' : ''}>
        <Grid container={true} alignItems="center">
          <Grid item={true} style={{ width: 'calc(100%)', wordWrap: 'break-word' }}>
            {parts.map((part, index) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                component="span"
                // sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
              >
                {part.text}
              </Box>
            ))}
            <Typography variant="body2" color="secondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getOptionLabel = (option: PredictionOption | string): string =>
    typeof option === 'string' ? option : option.description;

  useImperativeHandle(
    ref,
    () => ({
      getSessionToken: () => {
        return sessionToken;
      },
      refreshSessionToken: () => {
        setSessionToken(new google.maps.places.AutocompleteSessionToken());
      },
    }),
    [sessionToken]
  );

  useEffect(() => {
    const init = async () => {
      try {
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          await new Loader({
            apiKey,
            ...{ libraries: ['places'], ...apiOptions },
          }).load();
        }
        initializeService();
      } catch (error) {
        onLoadFailed(error);
      }
    };

    if (apiKey) init();
    else initializeService();
  }, []);

  useEffect(() => {
    if (autoCompleteService && inputValue) {
      fetchSuggestions(inputValue);
    } else {
      setOptions([]);
      setDisplayValue('');
      setInternalSelectedOption(null);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      autoComplete={true}
      includeInputInList={true}
      filterSelectedOptions={true}
      noOptionsText="Introuvable"
      onChange={onSelectedOptionChange}
      onInputChange={onInputValueChange}
      loading={loading}
      loadingText="Chargement..."
      selectOnFocus={true}
      {...autocompleteProps}
      options={options}
      getOptionSelected={(val, val2) => {
        return val.place_id === val2?.place_id
      }}
      value={value ?? internalSelectedOption}
      renderInput={renderInputField}
      renderOption={renderAutocompleteOption}
      getOptionLabel={getOptionLabel}
      inputValue={displayValue || inputValue}
    />
  );
};

export default forwardRef(GooglePlacesAutocomplete);
