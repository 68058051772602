import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

interface StyledTabProps {
  label: string;
}

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      width: 'auto',
      fontWeight: 50,
      fontFamily: ['Poppins'].join(','),
      color: '#000A8C',
      opacity: 1,
      '&:hover': {
        color: '#000A8C',
        opacity: 1,
      },
      '&$selected': {
        fontWeight: 700,
        fontStyle: 'normal',
        color: '#0E1247',
      },
      '&:focus': {
        fontWeight: 700,
        fontStyle: 'normal',
        color: '#0E1247',
      },
      minWidth: '142px',
      fontSize: '0.9rem !important',
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple={true} {...props} />);

export default AntTab;
