import { Box, Card, CardContent, Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Check, FormatListBulleted, PeopleOutlineOutlined } from '@material-ui/icons';
import { OrientButton } from '@orientaction/commons';
import { JobLayout } from '@orientaction/components';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';

const JobBoard = (props: any) => {
  const { push } = useHistory();
  const classes = useStyles();

  return (
    <JobLayout>
      <div className={classes.root}>
        <div className={classes.container}>
          <h3 className={classes.h3}>
            <span>Bienvenue sur </span>
            <span className={classes.h3bold}>E-ORIENTACTION Emploi !</span>
          </h3>
          <hr className={classes.hr} />
          <Grid
            container={true}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.grid}>
            <Grid item={true} xs={12} lg={4} md={4}>
              <OrientButton
                className={clsx(classes.btnTest, classes.newBtnResu)}
                onClick={() => push('/admin/emploi/annonces')}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <FormatListBulleted fontSize="large" />
                  <div className={classes.newLabelBtn}>Consulter mes annonces </div>
                </Box>
              </OrientButton>
            </Grid>
            <Grid item={true} xs={12} lg={4} md={4}>
              <OrientButton
                className={clsx(classes.btnTest, classes.newBtnTest)}
                onClick={() => push('/admin/emploi/candidats')}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <PeopleOutlineOutlined fontSize="large" />
                  <div className={classes.newLabelBtn}>Consulter les candidat(e)s</div>
                </Box>
              </OrientButton>
            </Grid>
            <Grid item={true} xs={12} lg={4} md={4}>
              <OrientButton
                className={clsx(classes.btnTest, classes.newBtnRes)}
                onClick={() => push('/admin/emploi/annonces/new')}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Check fontSize="large" />
                  <div className={classes.newLabelBtn}>Publier une annonce</div>
                </Box>
              </OrientButton>
            </Grid>
          </Grid>

          <Grid
            container={true}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.presenterContainer}>
            <Grid item={true} xs={12}>
              <Grid container={true} justifyContent="center" spacing={2}>
                <Hidden only={['xs', 'sm', 'md']}>
                  <Grid item={true} lg={3} />
                </Hidden>
                <Hidden only={['xs', 'sm']}>
                  <Grid item={true} lg={1} md={1} className={classes.presenterImage}>
                    <img src="/images/job/board-presenter2.png" alt="presenter" />
                  </Grid>
                </Hidden>
                <Grid item={true} xs={12} md={11} lg={8}>
                  <Card className={classes.presenterCard}>
                    <CardContent>
                      <Grid container={true}>
                        <Hidden only={['xs', 'sm']}>
                          <Grid item={true} lg={3} md={4} />
                        </Hidden>
                        <Grid item={true} xs={12} lg={8} md={8} className={classes.presenterText}>
                          <div>
                            <Typography variant="inherit" noWrap={true} paragraph={true}>
                              “Le secret de la réussite est de savoir s&#39;entourer des meilleurs.”
                            </Typography>
                            <Typography variant="inherit" noWrap={true} paragraph={true}>
                              John Rockefeller
                            </Typography>

                            <Typography variant="inherit" paragraph={true}>
                              Associer la bonne personne à la bonne fonction est essentiel pour les
                              organisations qui visent la performance et l’efficacité. Pour les
                              collaborateurs, un bon matching se traduit par un bien-être accru, car
                              ils se sentent valorisés et motivés dans un environnement où leurs
                              aptitudes sont pleinement exploitées. Cela crée une dynamique positive
                              où la satisfaction personnelle et professionnelle se rejoignent.{' '}
                            </Typography>
                            <Typography
                              variant="inherit"
                              noWrap={true}
                              paragraph={true}
                              className={classes.presenteAuthor}>
                              Dr. Emeric Lebreton
                            </Typography>
                            <Typography
                              variant="inherit"
                              noWrap={true}
                              paragraph={true}
                              className={classes.i}>
                              Président du groupe ORIENTACTION
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item={true} xs={1} />
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </JobLayout>
  );
};

export default JobBoard;
