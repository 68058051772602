/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import { Button, Grid, Theme, useMediaQuery } from '@material-ui/core';
import { UserProfileForm, UserProfileHeader } from '@orientaction/components';
import { useUser } from '@orientaction/hooks';
import { LayoutResultat } from '@orientaction/layouts';
import style from './style';
import { useState } from 'react';
import { getFromLS } from '@orientaction/utils';

const UserProfileEdit = () => {
  const { user, updateUserById, loading, loadingUpdate }: any = useUser();

  const cls = style();

  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (currentUser.service && currentUser.service.id === 4) {
      setOpenModal(true);
    }
  };

  return (
    <LayoutResultat isWhite={true} showLogo={false} noPadding={true}>
      <>
        <UserProfileHeader />

        <Grid container={true} direction="row" className={cls.root}>
          <Grid item={true} xs={12} sm={12} md={3} lg={3} />

          <Grid item={true} xs={12} sm={12} md={9} lg={9}>
            {currentUser.service && currentUser.service.id === 4 && (
              <Button
                variant="contained"
                className={cls.btn}
                style={{ margin: '30px 0px 0px', background: 'white' }}
                onClick={handleOpenModal}>
                Modifier mes données de recrutement
              </Button>
            )}
            <UserProfileForm
              user={user || {}}
              updateUser={updateUserById}
              loading={loadingUpdate}
              loadingGetUser={loading}
            />
          </Grid>
        </Grid>
      </>
    </LayoutResultat>
  );
};

export default UserProfileEdit;
