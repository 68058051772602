/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';
import { ReactComponent as M3 } from '../../../../Asset/svg/matching3.svg';
import { useHistory } from 'react-router-dom';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const MatchingPersonality = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const personality = ['intéraction', 'imagination', 'empathie', 'action', 'adaptation'];
  const personalityProfil = ['intéraction', 'imagination', 'empathie', 'observation', 'organisation'];

  const salaire: string = '30000';
  const salaireProfil: string = '40000';

  return (
    <div className={classes.containerMatch}>
      <Grid container={true} alignItems="center" justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <M3 style={{ width: 30, height: 33, marginRight: 15 }} />
          <Typography className={classes.matchTitle}>
            Matching <span>Personnalité</span>
          </Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <CircularProgressCustom
            value={60}
            color="#FE5756"
            size={40}
            fontSize={12}
            fontSizeFloat={12}
            isPercent={true}
          />
        </Grid>
      </Grid>
      {/* Test des 7 besoins Needs pro® */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Test des 7 besoins Needs pro®</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès résultats
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* Header */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopLeftRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderLeft: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}
        />
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Requis
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopRightRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Candidat
            </Typography>
          </div>
        </Grid>
        {/* Header */}

        {/* body */}
        <Grid
          item={true}
          container={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <Grid item={true} lg={2} className={classes.celluleNumber}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                1
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={10} className={classes.celluleTexte}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Préférence environnementale
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: personalityProfil[0] !== personality[0] ? '#E63C3C' : '' }}>
              {personality[0]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={personalityProfil[0] === personality[0] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {personalityProfil[0] === personality[0] ? <CheckIcon /> : <CloseIcon />}
              {personalityProfil[0]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          container={true}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <Grid item={true} lg={2} className={classes.celluleNumber}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                2
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={10} className={classes.celluleTexte}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Préférence perceptive
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: personalityProfil[1] !== personality[1] ? '#E63C3C' : '' }}>
              {personality[1]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={personalityProfil[1] === personality[1] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {personalityProfil[1] === personality[1] ? <CheckIcon /> : <CloseIcon />}
              {personalityProfil[1]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          container={true}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <Grid item={true} lg={2} className={classes.celluleNumber}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                3
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={10} className={classes.celluleTexte}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Préférence émotionnelle
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: personalityProfil[2] !== personality[2] ? '#E63C3C' : '' }}>
              {personality[2]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={personalityProfil[1] === personality[1] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {personalityProfil[2] === personality[2] ? <CheckIcon /> : <CloseIcon />}
              {personalityProfil[2]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          container={true}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <Grid item={true} lg={2} className={classes.celluleNumber}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                4
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={10} className={classes.celluleTexte}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Préférence comportementale
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: personalityProfil[3] !== personality[3] ? '#E63C3C' : '' }}>
              {personality[3]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={personalityProfil[3] === personality[3] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {personalityProfil[3] === personality[3] ? <CheckIcon /> : <CloseIcon />}
              {personalityProfil[3]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          container={true}
          style={{
            borderBottomLeftRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <Grid item={true} lg={2} className={classes.celluleNumber}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                5
              </Typography>
            </div>
          </Grid>
          <Grid item={true} lg={10} className={classes.celluleTexte}>
            <div className={classes.cellule}>
              <Typography variant="h6" className={classes.celluleFigure}>
                Préférence environnementale
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderRight: '1px solid #5E65A3', borderBottom: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: personalityProfil[2] !== personality[2] ? '#E63C3C' : '' }}>
              {personality[2]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomRightRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={personalityProfil[2] === personality[2] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {personalityProfil[2] === personality[2] ? <CheckIcon /> : <CloseIcon />}
              {personalityProfil[2]}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* Test des 7 besoins Needs pro® */}
    </div>
  );
};

export default MatchingPersonality;
