/* eslint-disable no-shadow */
export enum StatusOrderEnum {
  asc = 'asc',
  desc = 'desc',
}
export type Ihead = {
  name: string;
  xs: string;
  sort: boolean;
  field?: string;
  type?: StatusOrderEnum;
};

export type CandidatureType = {
  user: any;
  status: string;
};

export type JobType = {
  id: number;
  title: string;
  adress: string;
  addressCity: string;
  addressLat: number;
  addressLng: number;
  addressZipcoe: string;
  intro: string;
  year_experiences: any;
  disponibilities: any;
  degree_levels: any;
  languages: any;
  candidatures: Array<CandidatureType>;
  createdAt: Date;
  publishedAt: Date;
};

export type CandidatureTableType = {
  firstname: string;
  lastname: string;
  age: number;
  genre: string;
  ville: string;
  diplome: string;
  phonenumber: string;
  email: string;
  avancement: string;
};

export type JobTableType = {
  title: string;
  adress: string;
  date: string;
  status: string;
  candidats: number;
  id: number;
};
