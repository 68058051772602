/* eslint-disable no-unneeded-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { FC, useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#2c57a5',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
    '@media only screen and (max-width: 600px)': {
      paddingTop:10,
      paddingBottom:10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop:10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom:10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  },
  cardBgGrey: {
    background: '#e8eef4'
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important'
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
  radioGrp: {
    width: '100%',
    borderRadius: 4,
  },
  controlLabel: {
    margin: '0 !important',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  radioItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #0E1247',
  },
  divider: {
    color: 'black',
    '&.MuiDivider-vertical': {
      height: '50%',
    },
  },
});

const MyRadio = withStyles({
  root: {
    display: 'none',
    '& + span': {
      height: '70px',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      borderRadius: 3,
      color: '#2c57a5',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& + span:hover': {
      border: '1px solid #2c57a5',
    },
    margin: 0,
  },
  checked: {
    '& + span': {
      color: '#fff',
      background: '#2c57a5',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const TableOrganisation = (props: any) => {
  const classes = useStyles();
  const { texts, index, defaultPropsValue, onChange } = props;
  const [defaultValue, setDefaultValue] = useState<any>(defaultPropsValue);
  console.log({defaultValue, defaultPropsValue})

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const found = findIndex(defaultValue, function(o) { return o === (event.target as HTMLInputElement).value})
    let data = cloneDeep(defaultValue)
    if (found === -1) {
      if (defaultValue.length < 10) {
        data = [...data, (event.target as HTMLInputElement).value];
      }
    } else {
      data.splice(found, 1);
    }
    setDefaultValue(data);
    onChange(data);
  };

  useEffect(() => {
    if (defaultPropsValue) {
      setDefaultValue(defaultPropsValue);
    }
  }, [defaultPropsValue])

  return (
    <Card variant="outlined">
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <RadioGroup
            className={clsx(classes.radioGrp)}
            aria-label='data'
            name='data'
            value={defaultValue}>
            <Grid container={true} justifyContent="flex-end">
              {texts.map((text: any, i: number) => (
                <Grid key={i} item={true} xs={12} sm={4} style={{textAlign: 'center', height: '100%', border: '0.5px solid #2b56a5',}}>
                  <FormControlLabel
                    key={i}
                    className={classes.controlLabel}
                    value={text}
                    control={<MyRadio onChange={handleChange} checked={defaultValue.indexOf(text) === -1 ? false : true} />}
                    label={text}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableOrganisation;
