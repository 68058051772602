/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1, publicSvgPath } from '../../../../utils/constants';
import clsx from 'clsx';
import { Page } from '@orientaction/components';
import { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';

const Page32 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={32}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          MIEUX CONNAÎTRE <br />
          VOTRE VÉRITABLE PERSONNALITÉ
        </Typography>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}>
            <strong>
              « La jeunesse est la période où l’on se déguise, où l’on cache sa <br />
              personnalité. C’est une période de mensonges sincères. »
            </strong>
          </Typography>
          <br />
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}>
            Pablo Picasso
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '60px', marginBottom: '60px' }}
        />
        <div style={{ textAlign: 'center' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}>
            <strong>
              « Nous sommes à la fois un fluide qui se solidifie, un trésor qui
              <br />
              s’appauvrit, une histoire qui s’écrit, une personnalité qui se crée. »
            </strong>
          </Typography>
          <br />
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}>
            Alexis Carrel
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '50px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          La personnalité est l’une des plus grandes richesses des êtres humains. Elle est ce qui
          les caractérise, ce qui les rend uniques. Mais elle est aussi à la source de leurs
          talents. La personnalité ne correspond pas seulement au caractère ou au tempérament d’une
          personne. Elle est ce qui relie l’appétence à la compétence, le plaisir de faire aux
          talents. Imaginez une pièce de monnaie dont une face serait le talent et l’autre le
          plaisir de faire et vous avez une belle image de la personnalité alliant à la fois ce que
          l’on aime faire et ce que l’on fait bien.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Beaucoup de personnes méconnaissent leur personnalité véritable. Ces personnes se
          comportent en fonction de ce que les autres attendent d’elles, de ce qu’elles pensent être
          acceptable et non en fonction de qui elles sont réellement. La famille, l’école puis plus
          tard leur employeur(se) les ont amenées progressivement à renoncer à leurs appétences et à
          leurs compétences profondes, ce qui correspondait à leur véritable personnalité. Ces
          personnes ne sont plus elles-mêmes. Elles portent un masque qui devient avec les années de
          plus en plus lourd à porter. Et à ôter !
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Ce que vous propose ORIENTACTION, avec toute l’humilité qui caractérise notre approche,
          c’est de partir à la découverte de cette personnalité profonde, de mieux la connaître pour
          mieux la révéler et mieux l’affirmer. Votre bilan de compétences est l’occasion pour vous
          de faire évoluer votre activité professionnelle (et votre vie personnelle) vers des
          activités plus en lien avec votre personnalité véritable. Plus votre métier sera en lien
          avec votre personnalité véritable et plus vous serez heureux(se) et performant(e) dans ce
          que vous faites.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '60%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
        marginTop: '0px !important',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '20px',
      color: '#2b56a5',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 20,
      background: '#E6E6E6',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);
export default Page32;
