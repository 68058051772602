import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: 20,
    paddingRight: 75,
    marginBottom: 100,
    [theme.breakpoints.down('lg')]: {
      width: '95%',
      margin: '2vh auto',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 0,
    }
  },
  btn: {
    border: '2px solid black',
    background: 'white',
    color: 'black',
    boxShadow: 'none',
  }
}));
