import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  gridParent: {
    display: 'flex',
    padding: '10px 15px',
    background: '#F1F1F1',
    width: 'calc(100% - 30px)',
  },
  title: {
    fontSize: 12,
    margin: 0,
    fontFamily: 'Poppins !important',
    fontWeight: 500,
    color: '#818295',
  },
  titleContainer: {
    display: 'flex',
  },
  icon: {
    width: 30,
    cursor: 'pointer',
  },
  bodyList: {
    padding: '10px 15px',
    background: 'white',
    width: 'calc(100% - 30px)',
  },
  loader: {
    width: 35,
    height: 35,
    display: 'block',
    margin: 'auto',
  },
  iconM: {
    width: 15,
    height: 'auto !important',
    cursor: 'pointer',
    marginLeft: 5,
  },
});
