/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import ReactFlagsSelect from 'react-flags-select';
import { isAdmin } from '@orientaction/utils';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserProfilePhotoContext } from '../../../../Context/UserProfilePhotoContext';
import useResponseUser from '../../../../hooks/useResponseUser';
import { connectUser, modifyUser } from '../../../../services/user.service';
import { emailMatch, publicSvgPath } from '../../../../utils/constants';
import locale from './locale.json';
import { useOfflineLanguage } from '@orientaction/hooks';
import { apiServiceNative } from '../../../../utils/fetchNative';

const LoginForm = () => {
  const cls = style();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [connectError, setConnectError] = useState<string>('');

  const { language, onLanguageChange } = useOfflineLanguage();
  const { postResponseUserConnected, responses } = useResponseUser();

  const history = useHistory();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<any>({
    mode: 'onChange',
  });
  const { resetPhoto, updateUserProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);
    const idGameResult = new URLSearchParams(search).get('idResult');
    const idGame = new URLSearchParams(search).get('idGame');
    console.log({ idGameResult });

    try {
      resetPhoto();
      const connectData = await connectUser({ ...data, identifier: data.email });
      if (connectData?.data?.jwt) {
        // eslint-disable-next-line no-new
        new Promise((resolve: any) => {
          localStorage.setItem('userInfos', JSON.stringify(connectData?.data?.user));
          localStorage.setItem('userToken', connectData?.data?.jwt);
          resolve(connectData?.data?.jwt);
        }).then(async token => {
          updateUserProfilePhoto();
          if (responses) {
            const dataToPost = {
              ...responses,
              users_permissions_users: [connectData?.data?.user.id],
            };
            const datareturn = await postResponseUserConnected(dataToPost, token);
            history.push(`/congrats/${datareturn.id}`);
          } else {
            if (idGameResult) {
              const updatedGameEdit = await apiServiceNative.put(
                `/api/update-result-game/${idGameResult}`,
                {
                  user_id: connectData?.data?.user.id,
                }
              );
              if (idGame) {
                const deletedGameResult = await apiServiceNative.post(
                  `/api/delete-result-game-not-needed`,
                  {
                    user_id: connectData?.data?.user.id,
                    idResult: idGameResult,
                    idGame,
                  }
                );
                console.log({ updatedGameEdit, deletedGameResult });
              }
              history.push(`/game/play/${idGameResult}/0`);
            } else {
              if (isAdmin(connectData?.data?.user)) {
                if (connectData?.data?.user?.allowed_services) {
                  const services = connectData?.data?.user?.allowed_services.map(
                    (i: { id: string }) => i.id
                  );
                  if (services.length) {
                    if (services.length === 1 && services[0] === 4) {
                      history.push('admin/emploi/board');
                      return;
                    }
                  }
                }

                history.push('admin/users-resultats');
              } else {
                history.push('/main');
              }
            }
          }
        });
        // const role = connectData?.data?.user?.role?.name ?? 'other';
        // when user did one test and he was not connected
      }
    } catch (error) {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        setConnectError(
          "Une erreur s'est produite. Vérifier que vous avez rentré correctement vos identifiants"
        );
      } else {
        setConnectError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      const userIdToConfirm = new URLSearchParams(search).get('confirmation');
      if (userIdToConfirm !== null && userIdToConfirm !== undefined && userIdToConfirm !== '') {
        await modifyUser({ id: userIdToConfirm, confirmed: true });
      }
    })();
  }, []);

  return (
    <div className={cls.formContainer}>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={cls.form}>
        <Box className={cls.fieldWithLabel}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value: emailMatch,
                message: 'Veuillez saisir une adresse e-mail valide',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="email">
                  <h5 className={cls.labelText}>{locale.email}</h5>
                </label>
                <Input
                  placeholder={locale.emailPlaceholder}
                  {...field}
                  type="text"
                  name="email"
                  className={cls.input}
                />
                {errors?.email?.message && (
                  <span className={cls.errorField}>{(errors as any).email.message}</span>
                )}
              </>
            )}
          />
        </Box>
        <Box className={cls.fieldWithLabel}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value:
                  /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                message:
                  'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : | - Lettres minuscules (a-z) | - Lettres majuscules (A-Z) | - Chiffres (0-9) | - Caractères spéciaux (ex. !@#)}',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="password">
                  <h5 className={cls.labelText}>{locale.password}</h5>
                </label>
                <Input
                  placeholder={locale.passwordPlaceholder}
                  {...field}
                  type={eyeOn ? 'text' : 'password'}
                  className={clsx(cls.input, cls.inputPassword)}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      {eyeOn ? (
                        <img
                          alt="eye-switch-on"
                          src={`${publicSvgPath}/eyeOn.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(false)}
                          onKeyDown={() => setEyeOn(false)}
                        />
                      ) : (
                        <img
                          alt="eye-switch-off"
                          src={`${publicSvgPath}/eyeOff.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(true)}
                          onKeyDown={() => setEyeOn(true)}
                        />
                      )}
                    </InputAdornment>
                  }
                />

                {errors?.password?.message && (
                  <span className={cls.passwordErrorField}>{(errors as any).password.message}</span>
                )}
              </>
            )}
          />
        </Box>
        {connectError?.trim()?.length > 0 && <h5 className={cls.errorField}>{connectError}</h5>}
        <Box className={cls.btnConnectContainer}>
          <Button
            type="submit"
            className={cls.btnConnect}
            disabled={isLoading}
            data-cy="login-user">
            {!isLoading ? locale.connectText : `${locale.connectText}...`}
          </Button>
        </Box>
        <Box className={cls.forgotPasswordLink}>
          <Link className={cls.forgotPasswordLinkText} to="/forgot-password">
            {locale.forgotPasswordText}
          </Link>
        </Box>
        <Box className={cls.forgotPasswordLink}>
          <ReactFlagsSelect
            countries={['FR', 'GB']}
            showOptionLabel={false}
            showSelectedLabel={false}
            fullWidth={false}
            selected={language}
            onSelect={onLanguageChange}
          />
        </Box>
      </form>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    '@media (min-width: 1250px)': {
      maxWidth: 410,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldWithLabel: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginTop: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputPassword: {
    paddingRight: 5,
  },
  labelText: {
    margin: 'auto',
    font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
  },
  btnConnectContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
  },
  btnConnect: {
    width: '100%',
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
  },
  forgotPasswordLink: {
    marginTop: 20,
  },
  forgotPasswordLinkText: {
    color: '#000A8C',
    textDecoration: 'underline',
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
  },
  passwordErrorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    minHeight: 20,
    maxWidth: 330,
  },
  eyeImg: {
    cursor: 'pointer',
    zIndex: 1,
  },
  inputWitAdornment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

export default LoginForm;
