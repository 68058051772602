import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerSection: {
      width: '80%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    matchTitle: {
      fontFamily: 'Poppins !important',
      fontSize: 20,
      fontWeight: 500,
      color: '#74B089',
      '& span': {
        fontFamily: 'Poppins !important',
        fontWeight: 700,
      },
    },
    containerMatch: {
      padding: 40,
      borderRadius: 5,
      border: '1px solid #FFF',
      background: 'linear-gradient(180deg, #FFF 0%, #F7F6F6 100%)',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
    },
    btnPrimarySeek: {
      background: '#000A8C',
      textTransform: 'none',
    },
    titleTable: {
      color: '#000A8C',
      fontFamily: 'Poppins',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
    },
    cellule: {
      padding: '10px',
    },
    celluleHeader: {
      color: '#151528',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      textAlign: 'center',
    },
    celluleFigure: {
      color: '#151528',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 300,
    },
    celluleMatch: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      '& svg': {
        fontSize: 17,
        marginRight: 10,
      }
    },
    celluleOK: {
      padding: 10,
      background: 'rgba(92, 202, 40, 0.30)',
      '& h6': {
        color: '#46AB17',
        display: 'flex',
        alignItems: 'center',
      },
    },
    celluleKO: {
      padding: 10,
      background: 'rgba(230, 60, 60, 0.10);',
      '& h6': {
        color: '#E63C3C',
        display: 'flex',
        alignItems: 'center',
      },
    },
    separator: {
      width: '100%',
      height: 1,
      background: '#E4E5EF',
      marginTop: 20,
    },
  })
);
