export const languages = [
  'Afrikaans',
  'Albanais',
  'Amharique',
  'Anglais',
  'Arabe (Standard)',
  'Arménien',
  'Assamais',
  'Aymara',
  'Baloutchi',
  'Basque',
  'Bengali',
  'Birman (Myanmar)',
  'Bhojpuri',
  'Bosnien',
  'Breton',
  'Bulgare',
  'Cantonais',
  'Catalan',
  'Chinois Wu (Shanghaïen)',
  'Coréen',
  'Croate',
  'Curdo',
  'Danois',
  'Espagnol',
  'Estonien',
  'Farsi (Persan)',
  'Finnois',
  'Français',
  'Gaélique écossais',
  'Gaélique irlandais',
  'Géorgien',
  'Grec',
  'Gujarati',
  'Haoussa',
  'Hébreu',
  'Hindi',
  'Hongrois',
  'Hawaïen',
  'Igbo',
  'Indonésien (Malais)',
  'Islandais',
  'Italien',
  'Javanais',
  'Japonais',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Kirghize',
  'Konkani',
  'Laotien (Lao)',
  'Letton',
  'Lituanien',
  'Luxembourgeois',
  'Malais',
  'Malgache',
  'Maltais',
  'Maori',
  'Marathi',
  'Maithili',
  'Mandar (Chinois Standard)',
  'Nauruan',
  'Népalais',
  'Norvégien',
  'Ouzbek',
  'Pashto',
  'Pendjabi',
  'Portugais',
  'Roumain',
  'Russe',
  'Samoan',
  'Serbe',
  'Sesotho',
  'Sindhi',
  'Slovaque',
  'Slovène',
  'Somali',
  'Suédois',
  'Swahili',
  'Tamoul',
  'Tchèque',
  'Tchétchène',
  'Tadjik',
  'Tagalog',
  'Telugu',
  'Thaïlandais',
  'Tok Pisin',
  'Turc',
  'Turkmène',
  'Ukrainien',
  'Vietnamien',
  'Xhosa',
  'Yoruba',
  'Zoulou',
];
