import { InputAdornment } from '@material-ui/core';
import { listvalueArray } from '@orientaction/Context';
import {
  figure32_page1,
  figure32_page2,
  figure32_page3,
  figure32_page4,
  figure32_page5,
} from '@orientaction/utils';
import { cloneDeep } from 'lodash';
import concat from 'lodash/concat';
import { list } from '../../../../pages/Book/EbookEtape_4/pages/34/constant';
import { languages } from './language.constant';
import { IMachingData } from './MatchSection/type';

const allFiguresProtected = concat(
  figure32_page1,
  figure32_page2,
  figure32_page3,
  figure32_page4,
  figure32_page5
);

const metiers = allFiguresProtected.slice(0).map(i => i.type);
const valeursens = listvalueArray.map(i => i.title);
const besoinsValues = [
  'Physiologiques',
  'Sécurité',
  'Appartenance verticale',
  'Appartenance horizontale',
  'Accomplissement',
  'Réalisation de soi',
  'Spiritualité',
  'Éternité',
];
const softSkillsHarmonyValues = list.map(i => i.label);
export const matching_sens_du_travail: { [key: string]: IMachingData } = {
  destins: {
    title: 'Sélectionnez dans l’ordre voulu 3 figures du destin parmi les 32.',
    dependency: true,
    items: [
      {
        name: 'Figure 1',
        required: true,
        type: 'select',
        placeholder: 'Prince',
        options: cloneDeep(metiers),
      },
      {
        name: 'Figure 2',
        required: true,
        type: 'select',
        placeholder: 'Capitaine',
        options: cloneDeep(metiers),
      },
      {
        name: 'Figure 3',
        required: true,
        type: 'select',
        placeholder: 'Sage',
        options: cloneDeep(metiers),
      },
    ],
  },
  sens: {
    title: "Sélectionnez dans l'ordre voulu 3 valeurs relevées par le test Sens®.",
    dependency: true,
    items: [
      {
        name: 'Valeur 1',
        required: true,
        type: 'select',
        placeholder: 'Autonomie',
        options: cloneDeep(valeursens),
      },
      {
        name: 'Valeur 2',
        required: true,
        type: 'select',
        placeholder: 'Stimulation',
        options: cloneDeep(valeursens),
      },
      {
        name: 'Valeur 3',
        required: true,
        type: 'select',
        placeholder: 'Réussite',
        options: cloneDeep(valeursens),
      },
    ],
  },
};

export const matching_besoins_et_motivation: { [key: string]: IMachingData } = {
  principaux_needspro: {
    title: "Sélectionnez dans l'ordre voulu 3 besoins principaux révélés par le test Needs Pro®",
    dependency: true,
    items: [
      {
        name: 'Besoin 1',
        required: true,
        type: 'select',
        placeholder: 'Sécurité',
        options: cloneDeep(besoinsValues),
      },
      {
        name: 'Besoin 2',
        required: true,
        type: 'select',
        placeholder: 'Physiologiques',
        options: cloneDeep(besoinsValues),
      },
      {
        name: 'Besoin 3',
        required: true,
        type: 'select',
        placeholder: 'Etérnité',
        options: cloneDeep(besoinsValues),
      },
    ],
  },
  salaire: {
    title: '',
    items: [
      {
        name: 'Indiquer le salaire annuel brut offert',
        required: true,
        type: 'input',
        inputEndIcon: '€',
        inputProps: {
          type: 'number',
          onInput: (e: any) => {
            e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 9);
          },
          inputProps: {
            min: 0,
            type: 'number',
          },
        },
        placeholder: 'Compléter',
        icon: '€',
      },
    ],
  },
};

export const matching_personnalite: { [key: string]: IMachingData } = {
  principaux_needspro: {
    title: '',
    items: [
      {
        name: 'Préférence relationnelle',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        allowDeselect: true,
        options: ['Interaction', 'Réflexion'],
      },
      {
        name: 'Préférence perceptive',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        allowDeselect: true,
        options: ['Expérience', 'Imagination'],
      },
      {
        name: 'Préférence émotionnelle',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        allowDeselect: true,
        options: ['Rationalité', 'Empathie'],
      },
      {
        name: 'Préférence comportementale',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        allowDeselect: true,
        options: ['Action', 'Observation'],
      },
      {
        name: 'Préférence environnementale',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        allowDeselect: true,
        options: ['Organisation', 'Adaptation'],
      },
    ],
  },
};
export const matching_talents: { [key: string]: IMachingData } = {
  soft_skills_harmony: {
    title: 'Test des 16 soft skills Harmony®',
    dependency: true,
    items: [
      {
        name: 'Choix 1',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        options: [...softSkillsHarmonyValues],
      },
      {
        name: 'Choix 2',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        options: [...softSkillsHarmonyValues],
      },
      {
        name: 'Choix 3',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        options: [...softSkillsHarmonyValues],
      },
      {
        name: 'Choix 4',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        options: [...softSkillsHarmonyValues],
      },
      {
        name: 'Choix 5',
        required: true,
        type: 'select',
        placeholder: 'Sélectionner',
        options: [...softSkillsHarmonyValues],
      },
    ],
  },
  hard_skills_languages: {
    title: 'Autoévaluation Hard skills',
    description: 'Sélectionnez dans l\'ordre jusqu’à trois langues étrangères',
    canEdit: true,
    editionData: {
      defaultCount: 3,
      itemTemplate: [
        {
          name: 'Langue',
          required: false,
          type: 'select',
          placeholder: 'Sélectionner',
          options: languages,
        },
        {
          name: 'Niveau de langue',
          required: false,
          type: 'select',
          placeholder: 'Sélectionner',
          options: ['Débutant', 'Avancé', 'Langue maternelle'],
        },
      ]
    },
    items: [
      {
        name: 'Langue 1',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: languages,
      },
      {
        name: 'Niveau de langue 1',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: ['Débutant', 'Avancé', 'Langue maternelle'],
      },
      {
        name: 'Langue 2',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: languages,
      },
      {
        name: 'Niveau de langue 2',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: ['Débutant', 'Avancé', 'Langue maternelle'],
      },
      {
        name: 'Langue 3',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: languages,
      },
      {
        name: 'Niveau de langue 3',
        required: false,
        type: 'select',
        placeholder: 'Sélectionner',
        options: ['Débutant', 'Avancé', 'Langue maternelle'],
      },
    ],
  },
  hard_skills_applications: {
    title: '',
    description: 'Sélectionnez 5 logiciels informatique',
    items: [
      {
        name: 'Traitement de Texte et Bureautique',
        required: false,
        type: 'radiomultiple',
        placeholder: 'Traitement de Texte et Bureautique',
        options: [
          'Microsoft Office Suite (Word, Excel, PowerPoint)',
          'Google Workspace (Docs, Sheets, Slides)',
          'LibreOffice',
          'Apple iWork (Pages, Numbers, Keynote)',
          'Autre',
        ],
      },
      {
        name: 'Communication et Collaboration',
        required: false,
        type: 'radiomultiple',
        placeholder: 'Communication et Collaboration',
        options: [
          'Slack',
          'Zoom',
          'Google Meet',
          'Skype for Business',
          'Gestion de Projets',
          'Trello',
          'Asana',
          'Monday',
          'Jira',
          'Basecamp',
          'Autre',
        ],
      },
      {
        name: 'CRM et Gestion de la Relation Client',
        required: false,
        type: 'radiomultiple',
        placeholder: 'CRM et Gestion de la Relation Client',
        options: [
          'VisualSalesforce',
          'HubSpot',
          'Zoho CRM',
          'Microsoft Dynamics 365',
          'Comptabilité et Finances',
          'QuickBooks',
          'Xero',
          'FreshBooks',
          'Sage Comptabilité',
          'EBP',
          'Odoo',
          'Studio Code',
          'Autre',
        ],
      },
      {
        name: 'Conception Graphique et Édition',
        required: false,
        type: 'radiomultiple',
        placeholder: 'Conception Graphique et Édition',
        options: ['Photoshop', 'Illustrator', 'InDesign', 'Canva', 'CorelDRAW', 'GIMP', 'Autre'],
      },
      {
        name: 'Développement et Codage',
        required: false,
        type: 'radiomultiple',
        placeholder: 'Développement et Codage',
        options: ['Visual Studio Code', 'Eclipse', 'IntelliJ IDEA', 'GitLab', 'GitHub', 'Autre'],
      },
      {
        name: 'Automatisation et Intégration',
        required: false,
        type: 'radiomultiple',
        placeholder: 'Automatisation et Intégration',
        options: ['Zapier', 'IFTTT', 'Automate.io', 'Autre'],
      },
      {
        name: 'IA Générative',
        required: false,
        type: 'radiomultiple',
        placeholder: 'IA Générative',
        options: ['Chat GPT', 'Google Bard', 'DALL·E', 'Midjourney', 'Copilot', 'Autre'],
      },
    ],
  },
};

export const matching_equilibre_de_vie: { [key: string]: IMachingData } = {
  importance_vie_pro: {
    title: '',
    items: [
      {
        name: 'Importance de la vie pro',
        placeholder: '',
        required: true,
        type: 'radio',
        options: ['Très forte', 'Forte', 'Moyenne', 'Faible'],
        optionsIcons: [4, 3, 2, 1],
      },
    ],
  },
};
