import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  gridParent: {
    display: 'flex',
    padding: '10px 15px',
    background: '#F1F1F1',
    width: 'calc(100% - 30px)',
  },
  title: {
    fontSize: 12,
    margin: 0,
    fontFamily: 'Poppins !important',
    fontWeight: 500,
    color: '#818295',
  },
  titleContainer: {
    display: 'flex',
  },
  icon: {
    width: 30,
    cursor: 'pointer',
  },
  bodyList: {
    padding: '10px 15px',
    background: 'white',
    width: 'calc(100% - 30px)',
  },
  loader: {
    width: 35,
    height: 35,
    display: 'block',
    margin: 'auto',
  },
  iconContainer: {
    position: 'relative',
  },
  filterR: {
    position: 'absolute',
    width: '50%',
    height: '100%',
    filter: "brightness(0) saturate(100%) invert(79%) sepia(50%) saturate(7159%) hue-rotate(89deg) brightness(125%) contrast(117%)"
  },
  iconM: {
    width: 15,
    height: 'auto !important',
    cursor: 'pointer',
    marginLeft: 5,
  },
  modalContent: {},
  modalText: {
    fontSize: 14,
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
  },
});
