/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
import { IconButton, Theme, Tooltip, withStyles } from '@material-ui/core';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import SimpleModal from '@orientaction/components/SimpleModal';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Border } from '../../../Asset/svg/border.svg';
import { ReactComponent as Check } from '../../../Asset/svg/check-3.svg';
import { ReactComponent as Deletec } from '../../../Asset/svg/deletered.svg';
import { ReactComponent as M1 } from '../../../Asset/svg/matching1.svg';
import { ReactComponent as M2 } from '../../../Asset/svg/matching2.svg';
import { ReactComponent as M3 } from '../../../Asset/svg/matching3.svg';
import { ReactComponent as M4 } from '../../../Asset/svg/matching4.svg';
import { ReactComponent as M5 } from '../../../Asset/svg/matching5.svg';
import { ReactComponent as View } from '../../../Asset/svg/view.svg';
import { ReactComponent as Warning } from '../../../Asset/svg/warning.svg';
import { useStyles } from './style';

const MatchingTooltip = withStyles((theme: Theme) => ({
  tooltip: ({ backgroundColor }: { backgroundColor: string }) => ({
    backgroundColor, // Utilisation de la prop bgColor
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: '8px 10px',
    lineHeight: 1,
  }),
}))(Tooltip);

interface IItemCandidatProps {
  dataColumns: any;
  // eslint-disable-next-line no-undef
  candidat: CandidatureTableType;
  handleIdToDelete?: (id: number) => any;
}
const ItemCandidat = ({ dataColumns, candidat, handleIdToDelete }: IItemCandidatProps) => {
  const classes = useStyles();
  const refVille = useRef(null);
  const refFirstname = useRef(null);
  const refLastname = useRef(null);
  const refEmail = useRef(null);
  const refPhonenumber = useRef(null);
  const [showTooltip, setShowTooltip] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    const refVilleData: any = refVille.current;
    const refFirstnameData: any = refFirstname.current;
    const refLastnameData: any = refLastname.current;
    const refEmailData: any = refEmail.current;
    const refPhonenumberData: any = refPhonenumber.current;
    let showTooltipCloned = cloneDeep(showTooltip);
    if (refVilleData) {
      showTooltipCloned = {
        ...showTooltipCloned,
        ville: refVilleData.scrollWidth > refVilleData.offsetWidth,
      };
    }
    if (refFirstnameData) {
      showTooltipCloned = {
        ...showTooltipCloned,
        firstname: refFirstnameData.scrollWidth > refFirstnameData.offsetWidth,
      };
    }
    if (refLastnameData) {
      showTooltipCloned = {
        ...showTooltipCloned,
        lastname: refLastnameData.scrollWidth > refLastnameData.offsetWidth,
      };
    }
    if (refEmailData) {
      showTooltipCloned = {
        ...showTooltipCloned,
        email: refEmailData.scrollWidth > refEmailData.offsetWidth,
      };
    }
    if (refPhonenumberData) {
      showTooltipCloned = {
        ...showTooltipCloned,
        phonenumber: refPhonenumberData.scrollWidth > refPhonenumberData.offsetWidth,
      };
    }
    setShowTooltip(showTooltipCloned);
  }, [candidat]);

  const deleteCandidat = async (id: number) => {
    if (handleIdToDelete) {
      handleIdToDelete(id);
      setOpenModal(false);
    }
  };

  return (
    <div className={classes.itemParent}>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[0]} - 5px` }}>
        <div ref={refFirstname} className={classes.overflowText}>
          {candidat.firstname}
        </div>
        {showTooltip?.firstname && <div className={classes.tooltipLabel}>{candidat.firstname}</div>}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[1]} - 5px` }}>
        <div ref={refLastname} className={classes.overflowText}>
          {candidat.lastname}
        </div>
        {showTooltip?.lastname && <div className={classes.tooltipLabel}>{candidat.lastname}</div>}
      </div>
      <div
        className={classes.itemLine}
        style={{ width: `calc(${dataColumns[2]} - 5px`, textAlign: 'center' }}>
        <div className={classes.overflowText}>{candidat.genre}</div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[3]} - 5px` }}>
        <div className={classes.overflowText}>{candidat.age}</div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[4]} - 5px` }}>
        <div className={classes.overflowText}>{candidat.diplome}</div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[5]} - 5px` }}>
        <div ref={refPhonenumber} className={classes.overflowText}>
          {candidat.phonenumber}
        </div>
        {showTooltip?.phonenumber && (
          <div className={classes.tooltipLabel}>{candidat.phonenumber}</div>
        )}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[6]} - 5px` }}>
        <div ref={refEmail} className={classes.overflowText}>
          {candidat.email}
        </div>
        {showTooltip?.email && <div className={classes.tooltipLabel}>{candidat.email}</div>}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[7]} - 5px` }}>
        <div ref={refVille} className={classes.overflowText}>
          {candidat.ville}
        </div>
        {showTooltip?.ville && <div className={classes.tooltipLabel}>{candidat.ville}</div>}
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[8]} - 5px` }}>
        <div style={{ display: 'flex', alignItems: 'center', margin: '8px 0px' }}>
          <MatchingTooltip
            title="Matching sens du travail"
            arrow={true}
            placement="top"
            backgroundColor="#74B089">
            <M1 style={{ marginRight: 3, opacity: candidat.candidature?.sensMatching ? 1 : 0.5 }} />
          </MatchingTooltip>
          <MatchingTooltip
            title="Matching besoins et motivations"
            arrow={true}
            placement="top"
            backgroundColor="#B34B4B">
            <M2
              style={{ marginRight: 3, opacity: candidat.candidature?.besoinsMatching ? 1 : 0.5 }}
            />
          </MatchingTooltip>
          <MatchingTooltip
            title="Matching personnalité"
            arrow={true}
            placement="top"
            backgroundColor="#FE5756">
            <M3
              style={{
                marginRight: 3,
                opacity: candidat.candidature?.personalityMatching ? 1 : 0.2,
              }}
            />
          </MatchingTooltip>
          <MatchingTooltip
            title="Matching talents"
            arrow={true}
            placement="top"
            backgroundColor="#BC72A7">
            <M4
              style={{ marginRight: 3, opacity: candidat.candidature?.talentsMatching ? 1 : 0.5 }}
            />
          </MatchingTooltip>
          <MatchingTooltip
            title="Matching Equilibre de vie"
            arrow={true}
            placement="top"
            backgroundColor="#5B6FB6">
            <M5 style={{ opacity: candidat.candidature?.equilibreMatching ? 1 : 0.5 }} />
          </MatchingTooltip>
        </div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[9]} - 5px` }}>
        <div className={classes.status}>
          {candidat.avancement === 'en cours' ? <Warning /> : <Check />}
          <span
            style={{
              marginLeft: 7,
              color: candidat.avancement === 'en cours' ? '#E63C3C' : '#5CCA28',
            }}>
            {candidat.avancement}
          </span>
        </div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[10]} - 5px` }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgressCustom
            value={Math.round(candidat.candidature?.matchingGlobal ?? 0)}
            color="#000A8C"
            size={35}
            fontSize={12}
            fontSizeFloat={12}
            isPercent={true}
          />
        </div>
      </div>
      <div className={classes.itemLine} style={{ width: `calc(${dataColumns[11]} - 5px` }}>
        <div style={{ width: '100%' }}>
          <IconButton aria-label="delete">
            <View />
          </IconButton>
          <IconButton aria-label="delete">
            <Border />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => setOpenModal(true)}>
            <Deletec />
          </IconButton>
        </div>
      </div>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Êtes vous sûr de vouloir supprimer ce candidat de cette annonce"
        textClick="Oui"
        textClose="Non"
        handleClick={() => deleteCandidat(candidat.idCandidature)}
      />
    </div>
  );
};
export default ItemCandidat;
