/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';
import { ReactComponent as M2 } from '../../../../Asset/svg/matching2.svg';
import { useHistory } from 'react-router-dom';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const MatchingBesoinMotivation = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const besoin = ['Sécurité', 'Éternité', 'Esprit d’entreprendre'];
  const besoinProfil = ['Sécurité', 'Physiologiques', 'Éternité'];

  const salaire: string = '30000';
  const salaireProfil: string = '40000';

  return (
    <div className={classes.containerMatch}>
      <Grid container={true} alignItems="center" justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <M2 style={{ width: 30, height: 33, marginRight: 15 }} />
          <Typography className={classes.matchTitle}>
            Matching <span>Besoins et motivation</span>
          </Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <CircularProgressCustom
            value={60}
            color="#B34B4B"
            size={40}
            fontSize={12}
            fontSizeFloat={12}
            isPercent={true}
          />
        </Grid>
      </Grid>
      {/* Test des 7 besoins Needs pro® */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Test des 7 besoins Needs pro®</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès résultats
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* Header */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopLeftRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderLeft: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}
        />
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Requis
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopRightRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Candidat
            </Typography>
          </div>
        </Grid>
        {/* Header */}

        {/* body */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Besoin 1
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: besoinProfil[0] !== besoin[0] ? '#E63C3C' : '' }}>
              {besoin[0]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={besoinProfil[0] === besoin[0] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {besoinProfil[0] === besoin[0] ? <CheckIcon /> : <CloseIcon />}
              {besoinProfil[0]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Besoin 2
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: besoinProfil[1] !== besoin[1] ? '#E63C3C' : '' }}>
              {besoin[1]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={besoinProfil[1] === besoin[1] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {besoinProfil[1] === besoin[1] ? <CheckIcon /> : <CloseIcon />}
              {besoinProfil[1]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomLeftRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Besoin 3
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderRight: '1px solid #5E65A3', borderBottom: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: besoinProfil[2] !== besoin[2] ? '#E63C3C' : '' }}>
              {besoin[2]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomRightRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={besoinProfil[2] === besoin[2] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {besoinProfil[2] === besoin[2] ? <CheckIcon /> : <CloseIcon />}
              {besoinProfil[2]}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* Test des 7 besoins Needs pro® */}

      <div className={classes.separator} />

      {/* Autoévaluation */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Autoévaluation</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès ebook
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* Header */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopLeftRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderLeft: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}
        />
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Requis
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopRightRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Candidat
            </Typography>
          </div>
        </Grid>
        {/* Header */}

        {/* body */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Montant du salaire annuel souhaité
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: salaire !== salaireProfil ? '#E63C3C' : '' }}>
              {salaire}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={salaire === salaireProfil ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {salaire === salaireProfil ? <CheckIcon /> : <CloseIcon />}
              {salaireProfil}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* Autoévaluation */}
    </div>
  );
};

export default MatchingBesoinMotivation;
