/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, OutlinedInput } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './style';
import { ReactComponent as M1 } from '../../../../Asset/svg/matching1.svg';
import { useHistory } from 'react-router-dom';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const DetailCandidat = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const figure = ['Prince / Princesse', 'Gendarme', 'Teinturier'];
  const figureProfil = ['Prince / Princesse', 'Capitaine', 'Sage'];

  const valeurSens = ['Autonomie', 'Réussite', 'Stimulation'];
  const valeurSensProfil = ['Autonomie', 'Stimulation', 'Réussite'];

  return (
    <div className={classes.containerMatch}>
      <Grid container={true} alignItems="center" justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <M1 style={{ width: 30, height: 33, marginRight: 15 }} />
          <Typography className={classes.matchTitle}>
            Matching <span>Sens du travail</span>
          </Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <CircularProgressCustom
            value={60}
            color="#74B089"
            size={40}
            fontSize={12}
            fontSizeFloat={12}
            isPercent={true}
          />
        </Grid>
      </Grid>
      {/* Les figures du destin */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Les figures du destin</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès ebook
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* Header */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopLeftRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderLeft: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}
        />
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Requis
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopRightRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Candidat
            </Typography>
          </div>
        </Grid>
        {/* Header */}

        {/* body */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Figure n°1
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: figureProfil[0] !== figure[0] ? '#E63C3C' : '' }}>
              {figure[0]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={figureProfil[0] === figure[0] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {figureProfil[0] === figure[0] ? <CheckIcon /> : <CloseIcon />}
              {figureProfil[0]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Figure n°2
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: figureProfil[1] !== figure[1] ? '#E63C3C' : '' }}>
              {figure[1]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={figureProfil[1] === figure[1] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {figureProfil[1] === figure[1] ? <CheckIcon /> : <CloseIcon />}
              {figureProfil[1]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomLeftRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Figure n°3
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderRight: '1px solid #5E65A3', borderBottom: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: figureProfil[2] !== figure[2] ? '#E63C3C' : '' }}>
              {figure[2]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomRightRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={figureProfil[2] === figure[2] ? classes.celluleOK : classes.celluleKO}>
            <Typography variant="h6" className={classes.celluleMatch}>
              {figureProfil[2] === figure[2] ? <CheckIcon /> : <CloseIcon />}
              {figureProfil[2]}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* Les figures du destin */}

      <div className={classes.separator} />

      {/* Test des 10 valeurs de Sens® */}
      <Grid
        style={{ marginTop: 20 }}
        container={true}
        alignItems="center"
        justifyContent="space-between">
        <Grid item={true} lg="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.titleTable}>Test des 10 valeurs de Sens®</Typography>
        </Grid>
        <Grid item={true} lg="auto">
          <Button variant="contained" color="primary" className={classes.btnPrimarySeek}>
            Accès résultats
          </Button>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 20 }} container={true} item={true}>
        {/* Header */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopLeftRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderLeft: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}
        />
        <Grid
          item={true}
          lg={4}
          style={{
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Requis
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderTopRightRadius: 5,
            borderTop: '1px dashed #5E65A3',
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px dashed #5E65A3',
          }}>
          <div className={classes.cellule} style={{ padding: 20 }}>
            <Typography variant="h6" className={classes.celluleHeader}>
              Candidat
            </Typography>
          </div>
        </Grid>
        {/* Header */}

        {/* body */}
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Valeur n°1
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: valeurSensProfil[0] !== valeurSens[0] ? '#E63C3C' : '' }}>
              {valeurSens[0]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div
            className={
              valeurSensProfil[0] === valeurSens[0] ? classes.celluleOK : classes.celluleKO
            }>
            <Typography variant="h6" className={classes.celluleMatch}>
              {valeurSensProfil[0] === valeurSens[0] ? <CheckIcon /> : <CloseIcon />}
              {valeurSensProfil[0]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Valeur n°2
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: valeurSensProfil[1] !== valeurSens[1] ? '#E63C3C' : '' }}>
              {valeurSens[1]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderBottom: '1px solid #5E65A3', borderRight: '1px solid #5E65A3' }}>
          <div
            className={
              valeurSensProfil[1] === valeurSens[1] ? classes.celluleOK : classes.celluleKO
            }>
            <Typography variant="h6" className={classes.celluleMatch}>
              {valeurSensProfil[1] === valeurSens[1] ? <CheckIcon /> : <CloseIcon />}
              {valeurSensProfil[1]}
            </Typography>
          </div>
        </Grid>

        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomLeftRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderLeft: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div className={classes.cellule}>
            <Typography variant="h6" className={classes.celluleFigure}>
              Valeur n°3
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{ borderRight: '1px solid #5E65A3', borderBottom: '1px solid #5E65A3' }}>
          <div className={classes.cellule}>
            <Typography
              variant="h6"
              className={classes.celluleMatch}
              style={{ color: figureProfil[2] !== figure[2] ? '#E63C3C' : '' }}>
              {valeurSens[2]}
            </Typography>
          </div>
        </Grid>
        <Grid
          item={true}
          lg={4}
          style={{
            borderBottomRightRadius: 5,
            borderBottom: '1px solid #5E65A3',
            borderRight: '1px solid #5E65A3',
          }}>
          <div
            className={
              valeurSensProfil[2] === valeurSens[2] ? classes.celluleOK : classes.celluleKO
            }>
            <Typography variant="h6" className={classes.celluleMatch}>
              {valeurSensProfil[2] === valeurSens[2] ? <CheckIcon /> : <CloseIcon />}
              {valeurSensProfil[2]}
            </Typography>
          </div>
        </Grid>
      </Grid>
      {/* Test des 10 valeurs de Sens® */}
    </div>
  );
};

export default DetailCandidat;
