/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleRomain } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { imgBook2, publicBook3Path } from '../../../../utils/constants';
import TextArea from '@orientaction/commons/TextArea';
import { ebookRecValues } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { apiServiceNative } from '../../../../utils/fetchNative';

const Page52 = (props: any) => {
  const classes = useStyles();
  const [responseCondition5_, setResponseCondition5_] = useState<any>({});
  const [responseCondition6_, setResponseCondition6_] = useState<any>({});
  const [responseCondition7_, setResponseCondition7_] = useState<any>({});

  const ebookRecVal = useSelector(ebookRecValues);
  const { idEbook } = props;
  useEffect(() => {
    if (ebookRecVal) {
      setResponseCondition5_(ebookRecVal?.responseCondition5 || {});
      setResponseCondition6_(ebookRecVal?.responseCondition6 || {});
      setResponseCondition7_(ebookRecVal?.responseCondition7 || {});
    }
  }, [ebookRecVal]);
  const question1 = [
    {
      name: '',
      text: 'Transport en commun (train, tramway, bus)',
    },
    {
      name: '',
      text: 'Véhicule personnel (auto ou moto)',
    },
    {
      name: '',
      text: 'Micromobilité (vélo, trottinette, monocycle, etc.)',
    },
    {
      name: '',
      text: 'À pied',
    },
  ];
  const question2 = [
    {
      name: '',
      text: 'Voiture de fonction ou de société',
    },
    {
      name: '',
      text: 'Ordinateur portable',
    },
    {
      name: '',
      text: 'Téléphone portable',
    },
  ];
  const question3 = [
    {
      name: '',
      text: 'Vous êtes une personne plutôt ouverte à faire des heures supplémentaires quand cela est nécessaire.',
    },
    {
      name: '',
      text: 'Ce n’est pas possible pour vous. Vous ne préférez pas.',
    },
  ];

  const handleChange = async (e: any, index: number) => {
    const { name, checked } = e.target;
    let data = {};
    if (index === 1) {
      data = { [name]: checked };
      setResponseCondition5_(data);
    } else if (index === 2) {
      data = { ...responseCondition6_, [name]: checked };
      setResponseCondition6_(data);
    } else if (index === 3) {
      data = { [name]: checked };
      setResponseCondition7_({ [name]: checked });
    }
    const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
      data: {
        [`responseCondition${index + 4}`]: data,
      },
    });
  };
  const handleChangeText = async (e: any) => {
    const { name, value } = e.target;
    if (value !== '') {
      const data = { ...responseCondition6_, [name]: value };
      setResponseCondition6_({ ...responseCondition6_, [name]: value });
      const response = await apiServiceNative.put(`/api/ebook-recrutements/${idEbook}`, {
        data: {
          responseCondition6: data,
        },
      });
    }
  };

  const formatageText = (text: any) => {
    if (text === true || text === false) {
      return '';
    }
    return text;
  };

  return (
    <Page id={52} readOnly={props.readOnly}>
      <div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="Comment vous déplacez-vous généralement pour vous rendre au travail ?"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question1.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 1)}
                            checked={!!responseCondition5_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="En complément de votre rémunération, trouvez-vous indispensable de bénéficier des
            avantages suivants (cochez les cases correspondantes) :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question2.map((elem: any) => {
                    return (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={elem.text}
                              onChange={(e: any) => handleChange(e, 2)}
                              checked={!!responseCondition6_[elem.text]}
                            />
                          }
                          label={<div style={{ color: 'black' }}>{elem.text}</div>}
                        />
                        {elem.text === 'Autres (précisez) :' &&
                          !!responseCondition6_[elem.text] && (
                            <TextArea
                              name={elem.text}
                              value={formatageText(responseCondition6_[elem.text])}
                              onChange={handleChangeText}
                            />
                          )}
                      </div>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr text="Au niveau des heures supplémentaires :" isSmall={true} />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question3.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 3)}
                            checked={!!responseCondition7_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <img
          src={`${publicBook3Path}/rate_ma_vie.jpg`}
          alt="photo"
          className={classes.imgNeedFull}
          style={{ margin: 'auto', marginTop: 40, width: '60%' }}
        />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    imgNeedFull: {
      display: 'block',
      height: 'auto',
    },
  })
);
export default Page52;
