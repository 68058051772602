import { Table, TableCell, TableRow, Typography } from '@material-ui/core';
import { FC } from 'react';
import {
  matching_besoins_et_motivation,
  matching_equilibre_de_vie,
  matching_personnalite,
  matching_sens_du_travail,
  matching_talents,
} from './matching.constant';
import { useStyles } from './matchingFormStyle';
import MatchingSection from './MatchSection';

type MatchingFormProps = {
  job: any;
  errors: any;
  handleChange: (e: any) => void;
};
const MatchingForm: FC<MatchingFormProps> = ({ job, handleChange, errors }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3" className={classes.subtitle}>
        Données de matching
      </Typography>
      <MatchingSection
        errors={errors}
        id="matching_sens_du_travail"
        job={job}
        handleChange={handleChange}
        title="Matching Sens du travail"
        items={matching_sens_du_travail}
        color="#74B089"
        gridItemProps={{ xs: 12, sm: 4 }}
      />
      <MatchingSection
        errors={errors}
        id="matching_besoins_et_motivation"
        job={job}
        handleChange={handleChange}
        title="Matching Besoins et motivation"
        items={matching_besoins_et_motivation}
        color="#B34B4B"
        gridItemProps={{ xs: 12, sm: 4 }}
      />
      <MatchingSection
        errors={errors}
        id="matching_personnalite"
        job={job}
        handleChange={handleChange}
        title="Matching Personnalité"
        items={matching_personnalite}
        color="#FE5756"
        gridItemProps={{ xs: 12, sm: 6 }}
      />
      <MatchingSection
        errors={errors}
        job={job}
        id="matching_talents"
        handleChange={handleChange}
        title="Matching Talents"
        items={matching_talents}
        color="#BC72A7"
        gridItemProps={{ xs: 12, sm: 6 }}
      />
      <MatchingSection
        errors={errors}
        job={job}
        id="matching_equilibre_de_vie"
        handleChange={handleChange}
        title="Matching Equilibre vie"
        items={matching_equilibre_de_vie}
        color="#5B6FB6"
        gridItemProps={{ xs: 12, sm: 12}}
      />
    </div>
  );
};

export default MatchingForm;
