/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { MarginContainer, OaPopin, SelectHieachy } from '@orientaction/commons';
import { getFromLS } from '@orientaction/utils';
import { cloneDeep, filter, find, flatten, sortBy } from 'lodash';
import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from 'react-phone-input-2';
import { useSnackbar } from 'react-simple-snackbar';
import { apiServiceNative } from '../../../../utils/fetchNative';
import { FORM_MODE, generatePasswordOptionalText } from '../constant';
import { UserFormContext } from '../Context/UserFormContext';
import EbookSection from '../Option/EbookSection';
import ServiceSelection from '../Option/ServiceSelection';
import RandomPasswordSection from '../RandomPasswordSection';
import MajorTestSelection from './MajorTestSelection';
import style from './style';
import useBeneficiaryForm from './useBeneficiaryForm';

interface IProps {
  mode: string;
  companyUserRoleId: number;
  alias: any;
}

const BeneficiaryForm: FC<IProps> = ({ mode, companyUserRoleId, alias }) => {
  const {
    phoneNumberChange,
    languageChange,
    handleChange,
    handleSubmit,
    user,
    errors,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
    majorTestsLoading,
    booksLoading,
    loading,
    closeDrawer,
    tabs,
    canUpdateHierarchy,
    popin,
    handlePopinConfirm,
    handlePopinClose,
  }: any = useBeneficiaryForm(mode, companyUserRoleId);
  const { updateFormUser } = useContext<any>(UserFormContext);
  const {
    firstname,
    lastname,
    dayOfBirth,
    company_user_role,
    streetNumber,
    streetName,
    postal_code,
    town,
    phone,
    language,
    diploma,
    upper_hierarchy,
    company,
    Direction,
    email,
    rattachement,
    name_upper_hierarchy,
    tests,
    books,
    inCards,
    generatedPassword,
    gender,
    services,
    service,
    tests_to_do,
  } = user;

  const classes = style();

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  console.log({ userConnected });

  const btn = () => {
    if (loading) {
      return 'Chargement ... ';
    }

    if (mode === FORM_MODE.update) {
      return 'Enregistrer';
    }

    return 'Créer le compte';
  };
  const stickyStyle = {
    position: 'sticky',
    bottom: '0px', // vous pouvez ajuster ceci selon votre besoin
    backgroundColor: '#fff', // assure une couleur de fond si nécessaire
    zIndex: 1000, // z-index pour le placer au-dessus d'autres éléments
    padding: '10px', // optionnel pour ajouter de l'espace autour
  };

  const hasTests = !!tests?.length;
  const hasBooks = !!books?.length && service?.id === 1;
  const hasResources = hasTests || hasBooks;

  const userCanHaveManager = tabs !== 0 && company_user_role !== 1;
  const canSeeResources = service && upper_hierarchy;

  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: 'top-right',
    style: {
      backgroundColor: '#f44336',
      color: '#fff',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '16px',
      fontSize: '16px',
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      openSnackbar('merci de remplir les champs requis');
    }
  }, [errors]);

  const [categorieTests, setCategorieTests] = useState<any>();
  const [allTestsAllowed, setAllTestsAllowed] = useState<any>();

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = sessionStorage.getItem('categories-test')
          ? JSON.parse(sessionStorage.getItem('categories-test') || '')
          : [];
        const dataUserUpper = await apiServiceNative.get(
          `/api/users/${upper_hierarchy}?populate[category_tests][fields][0]=id`
        );
        if (response) {
          const categoriesToFilter = filter(response.data, (el: any) =>
            dataUserUpper?.category_tests?.some((res: any) => res.id === el.id)
          );
          setCategorieTests(categoriesToFilter);
          const allTests = flatten(categoriesToFilter.map(elem => elem.attributes.tests.data));
          setAllTestsAllowed(allTests);
        } else {
          console.error('Invalid response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };
    if (upper_hierarchy) {
      fetchGameDetails();
    }
  }, [upper_hierarchy]);

  const handleChangeTest = (e: any) => {
    const { value, checked } = e.target;
    const testToFind = find(allTestsAllowed, (test: any) => test.id === parseInt(value, 10));
    const testsWithoutAttributes = {
      id: testToFind.id,
      ...testToFind.attributes,
    };

    const userTests = cloneDeep(tests_to_do) || [];
    console.log({ userTests, testsWithoutAttributes });
    const isTestExistIntoUser = userTests?.some((elem: any) => elem?.id === parseInt(value, 10));

    if (!isTestExistIntoUser && checked) {
      updateFormUser({
        ...user,
        tests_to_do: [...userTests, testsWithoutAttributes],
      });
    } else {
      updateFormUser({
        ...user,
        tests_to_do: filter(userTests, (elem: any) => elem.id !== testsWithoutAttributes?.id),
      });
    }
  };

  return (
    <>
      <form
        onSubmit={(e: any) => {
          handleSubmit();

          e.preventDefault();
        }}>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Prénom </Typography>

          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="firstname"
            value={firstname || ''}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
            error={!!errors.firstname}
          />
        </div>
        <div className={classes.marginTop}>
          <Typography className={classes.label}>Nom </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            className={classes.formInput}
            onChange={handleChange}
            name="lastname"
            value={lastname || ''}
            error={!!errors.lastname}
            InputProps={{
              readOnly: !!(mode === FORM_MODE.readonly),
            }}
          />
          <div className={classes.marginTop}>
            <Grid container={true} direction="row" spacing={1}>
              <Grid item={true} md={6} xs={12}>
                <div
                  style={{
                    marginTop: '15px',
                  }}>
                  <Typography className={classes.label}>Date de naissance </Typography>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className={classes.formInput}
                    onChange={handleChange}
                    name="dayOfBirth"
                    value={dayOfBirth ? moment(dayOfBirth).format('YYYY-MM-DD') : ''}
                    type="date"
                    error={!!errors.dayOfBirth}
                    helperText={errors.dayOfBirth ? 'Ce champ est obligatoire' : ''}
                  />
                </div>
              </Grid>

              <Grid item={true} md={6} xs={12}>
                <div className={classes.marginTop}>
                  <Typography className={classes.label}>Sexe </Typography>

                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!errors.gender}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={gender || ''}
                      onChange={handleChange}
                      placeholder="Sexe"
                      name="gender"
                      className={classes.formControlSelect}
                      data-cy="form-gender">
                      <MenuItem value="Homme" data-cy="form-gender-homme">
                        Homme
                      </MenuItem>
                      <MenuItem value="Femme" data-cy="form-gender-femme">
                        Femme
                      </MenuItem>
                      <MenuItem value="Non binaire">Non binaire</MenuItem>
                    </Select>
                    {errors.gender && (
                      <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                        Ce champ est obligatoire
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </Grid>
              <Grid item={true} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>Adresse </Typography>
              </Grid>
              <Grid item={true} md={3} xs={12}>
                <div>
                  <Typography className={classes.subLabel}>N° de voie </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className={classes.formInput}
                    onChange={handleChange}
                    name="streetNumber"
                    value={streetNumber || ''}
                    error={!!errors.streetNumber}
                    InputProps={{
                      readOnly: !!(mode === FORM_MODE.readonly),
                    }}
                  />
                </div>
              </Grid>
              <Grid item={true} md={9} xs={12}>
                <div>
                  <Typography className={classes.subLabel}>Nom de la voie </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className={classes.formInput}
                    onChange={handleChange}
                    name="streetName"
                    value={streetName || ''}
                    error={!!errors.streetName}
                    InputProps={{
                      readOnly: !!(mode === FORM_MODE.readonly),
                    }}
                  />
                </div>
              </Grid>

              <Grid item={true} md={4} xs={12}>
                <div className={classes.marginTop}>
                  <Typography className={classes.subLabel}>Code postal </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className={classes.formInput}
                    onChange={handleChange}
                    name="postal_code"
                    value={postal_code || ''}
                    error={!!errors.postal_code}
                    InputProps={{
                      readOnly: !!(mode === FORM_MODE.readonly),
                    }}
                  />
                </div>
              </Grid>
              <Grid item={true} md={8} xs={12}>
                <div className={classes.marginTop}>
                  <Typography className={classes.subLabel}>Ville </Typography>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    className={classes.formInput}
                    onChange={handleChange}
                    name="town"
                    value={town || ''}
                    error={!!errors.town}
                    InputProps={{
                      readOnly: !!(mode === FORM_MODE.readonly),
                    }}
                  />
                </div>
              </Grid>

              <Grid item={true} md={12} xs={12}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} md={8} xs={12}>
                    <div className={classes.marginTop}>
                      <Typography className={classes.label}>Téléphone </Typography>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                        }}
                        inputClass={classes.telInput}
                        specialLabel=""
                        country="fr"
                        value={phone || ''}
                        onChange={phoneNumberChange}
                        masks={{
                          fr: '..........',
                        }}
                        preferredCountries={['fr', 'be', 'ch', 'ca']}
                        preserveOrder={['preferredCountries']}
                        inputStyle={{
                          width: '100%',
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item={true} md={4} xs={12}>
                    <div className={classes.marginTop}>
                      <Typography className={classes.label}>Langue </Typography>
                      <ReactFlagsSelect
                        countries={['FR', 'GB']}
                        showOptionLabel={false}
                        showSelectedLabel={false}
                        fullWidth={false}
                        selected={language === 2 ? 'GB' : 'FR'}
                        onSelect={languageChange}
                        selectButtonClassName={classes.selectLanguageButton}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} md={12} xs={12}>
                <div className={classes.marginTop}>
                  <Typography className={classes.label}>Diplôme </Typography>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    error={!!errors.diploma}>
                    <Select
                      labelId="diploma-label-id"
                      id="diploma-id"
                      value={diploma || ''}
                      onChange={handleChange}
                      placeholder="diploma"
                      name="diploma"
                      className={classes.formControlSelect}
                      data-cy="form-diploma">
                      <MenuItem value="Aucun diplôme">Aucun diplôme</MenuItem>
                      <MenuItem value="CAP/BEP">CAP/BEP</MenuItem>
                      <MenuItem value="BAC">BAC</MenuItem>
                      <MenuItem value="BAC+1">BAC+1</MenuItem>
                      <MenuItem value="BAC+2">BAC+2</MenuItem>
                      <MenuItem value="BAC+3">BAC+3</MenuItem>
                      <MenuItem value="BAC+4" data-cy="form-diploma-bac-4">
                        BAC+4
                      </MenuItem>
                      <MenuItem value="BAC+5">BAC+5</MenuItem>
                      <MenuItem value="BAC+6">BAC+6</MenuItem>
                      <MenuItem value="BAC+7">BAC+7</MenuItem>
                      <MenuItem value="BAC+8">BAC+8</MenuItem>
                    </Select>
                    {errors.diploma && (
                      <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                        Ce champ est obligatoire
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </div>
          {userCanHaveManager && (
            <>
              <SelectHieachy
                value={upper_hierarchy}
                alias={alias}
                errorForm={errors}
                readOnly={mode === FORM_MODE.readonly || canUpdateHierarchy}
                title={`Administrateur de rattachement ${
                  userConnected.role.type === 'admin' ? '' : '(facultatif)'
                }`}
                name="upper_hierarchy"
                handleChange={handleChange}
                company_user_role={company_user_role}
                company={company}
                equal={false}
                direction={Direction}
              />
              {errors?.upper_hierarchy && (
                <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                  Ce champ est obligatoire
                </FormHelperText>
              )}
            </>
          )}
          <div className={classes.marginTop}>
            <Typography className={classes.label}>Email </Typography>

            <TextField
              id="outlined-basic"
              variant="outlined"
              className={classes.formInput}
              onChange={handleChange}
              error={!!errors.email}
              InputProps={{
                readOnly: !!(mode === FORM_MODE.readonly),
              }}
              name="email"
              value={email || ''}
              helperText={errors.email ? 'Mauvais format' : ''}
            />
          </div>
          <div className={classes.marginTop}>
            <Typography className={classes.label}>
              Mot de passe
              <span
                style={{
                  fontWeight: 'lighter',
                  fontSize: 12,
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  fontStyle: 'italic',
                }}>
                ({generatePasswordOptionalText})
              </span>
            </Typography>
            <RandomPasswordSection
              password={generatedPassword}
              isCopied={isRandomPasswordCopied}
              generatePassword={generateRandomPassword}
              copyPassword={copyRandomPassword}
            />
          </div>
          {upper_hierarchy && company_user_role === 5 && (
            <>
              <ServiceSelection
                error={!!errors.service}
                services={services}
                handleChange={handleChange}
                service={service}
              />{' '}
              {errors?.service && (
                <FormHelperText style={{ color: 'red', marginTop: '4px' }}>
                  Ce champ est obligatoire
                </FormHelperText>
              )}
            </>
          )}

          {upper_hierarchy && company_user_role === 5 && (
            <>
              <MarginContainer>
                <Divider />
              </MarginContainer>

              {canSeeResources && hasResources && (
                <Typography className={classes.ressourceLabel}>Les ressources </Typography>
              )}
              {canSeeResources && hasTests && (
                <MajorTestSelection
                  tests={tests}
                  handleChange={handleChange}
                  loading={majorTestsLoading}
                  service={service}
                />
              )}
              {canSeeResources && hasBooks && (
                <MarginContainer>
                  <EbookSection
                    loading={booksLoading}
                    books={books}
                    handleChange={handleChange}
                    current={{ firstname, lastname, name_upper_hierarchy }}
                  />
                </MarginContainer>
              )}
              {/* <MarginContainer>
              <CardServiceSelection inCards={inCards} handleChange={handleChange} />
            </MarginContainer> */}
            </>
          )}
          {mode !== FORM_MODE.readonly && (
            <>
              {/* <MarginContainer>
              {error?.trim()?.length > 0 && <h5 className={classes.errorText}>{error}</h5>}
            </MarginContainer> */}
              <MarginContainer>
                <Typography>*Tous les champs sont obligatoires</Typography>
              </MarginContainer>
            </>
          )}
          {categorieTests && (
            <div style={{ marginTop: 30 }}>
              <MarginContainer>
                <Typography className={classes.bookChoiceLabel}> Les tests mineurs </Typography>
              </MarginContainer>
              {sortBy(categorieTests, (el: any) => el?.attributes?.priority)?.map(
                (categoryTest: any) => (
                  <div>
                    <Typography color="primary" style={{ fontStyle: 'italic' }}>
                      {categoryTest?.attributes?.name}
                    </Typography>
                    {sortBy(
                      categoryTest.attributes.tests.data,
                      (test: any) => test?.attributes?.priority
                    )?.map(
                      (testCat: any) =>
                        !testCat?.attributes?.isArchived &&
                        !testCat?.attributes?.isDesabled && (
                          <div className={classes.bookChoiceContainer}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="name"
                                  color="primary"
                                  value={testCat?.id}
                                  defaultChecked={tests_to_do?.some(
                                    (el: any) => el.id === testCat?.id
                                  )}
                                  onChange={handleChangeTest}
                                />
                              }
                              label={testCat?.attributes?.title}
                            />
                          </div>
                        )
                    )}
                  </div>
                )
              )}
            </div>
          )}

          <br />
          <div
            style={{
              position: 'sticky',
              bottom: '0px',
              backgroundColor: '#fff',
              padding: '10px',
              marginTop: 100,
            }}>
            <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item={true}>
                <Button className={classes.btnWhite} onClick={closeDrawer} disabled={loading}>
                  <span className={classes.textHeader}>
                    {mode === FORM_MODE.readonly ? 'Retour' : 'Annuler'}
                  </span>
                </Button>

                {mode !== FORM_MODE.readonly && (
                  <Button
                    type="submit"
                    className={classes.btnPrimary}
                    disabled={loading}
                    data-cy="submit-create-edit-admin-benef">
                    <span className={classes.textHeader}>{btn()}</span>
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </form>
      <OaPopin
        open={popin.open}
        title={popin.title}
        handleConfirm={handlePopinConfirm}
        handleClose={handlePopinClose}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {popin.content}
          </Typography>
        </div>
      </OaPopin>
    </>
  );
};

export default BeneficiaryForm;
